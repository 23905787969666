import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ClearIcon from '@mui/icons-material/Clear';
import CalculateIcon from '@mui/icons-material/Calculate';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { apiUrl } from '../../apiUrl/ApiUrl';
import '../../../css/tableStyle.css'

const today = new Date(); 
const randomCode = Math.floor(1000 + Math.random() * 9000);
const variable = `-${randomCode}`;
const formattedToday = today.getFullYear().toString().slice(-2) + (today.getMonth() + 1).toString().padStart(2, '0') + today.getDate().toString().padStart(2, '0') + variable;
const hahatoday = today.getFullYear() + (today.getMonth() + 1).toString().padStart(2, '0') + today.getDate().toString().padStart(2, '0');

const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: '機種',
      headerName: '機種',
      width: 150,
      editable: true,
    },
    {
      field: '圖號',
      headerName: '圖號',
      width: 150,
      editable: true,
    },
    {
        field: '項次',
        headerName: '項次',
        width: 60,
        editable: true,
    },
    {
        field: '物品名',
        headerName: '物品名',
        width: 150,
        editable: true,
    },
    {
        field: '單位',
        headerName: '單位',
        width: 150,
        editable: true,
    },
    {
        field: '訂單號碼',
        headerName: '訂單號碼',
        width: 150,
        editable: true,
    },
    {
        field: '訂單數量',
        headerName: '訂單數量',
        width: 150,
        editable: true,
    },
    {
        field: '訂購單價',
        headerName: '訂購單價',
        width: 150,
        editable: true,
    },
    {
        field: '客戶納期',
        headerName: '客戶納期',
        width: 150,
        editable: true,
    },
    {
        field: '批號',
        headerName: '批號',
        width: 150,
        editable: true,
    },
  ];

export default function HandOrder() {
    const [options, setOptions] = React.useState([]);
    const [itemoptions, setItemOptions] = React.useState([{
      id: 1,
      label: null,
      物品名: 'RF-306CI',
      圖號: "RF-306CI",
  }]);
    const [rows,setRows] = useState([]);
    const [filterPinum,setFilterPinum] = useState('');
    const [filterOrderNo,setFilterOrderNo] = useState('');
    const [filterDrawNum,setFilterDrawNum] = useState('');
    const [drawNum,setDrawNum] = useState('');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [editedRows, setEditedRows] = React.useState([]);
    const [selectedItemOption, setSelectedItemOption] = useState({
      id: 1,
      label: null,
      物品名: 'RF-306CI',
      圖號: "RF-306CI",
  });
    const [selectedOption, setSelectedOption] = useState({
        id: 753,
        label: null,
        manufacturer_Seq: "4500001",
        manufacturer_AbbreviationName: "台日",
        manufacturer_FullName: "台灣日立江森自控股份有限公司",
        manufacturer_UniformNum: "11915305",
        manufacturer_CityCode: "33810",
        manufacturer_FaxNum: "03-3245321",
        manufacturer_BossName: null,
        manufacturer_ContactName: null,
        manufacturer_CompanyAddress: "台北市南京東路三段63號",
        manufacturer_CompanyPhone: "03-3244711",
        manufacturer_CompanyFax: "03-3245321",
        manufacturer_ShipAddress: "桃園縣蘆竹鄉內厝村內溪路２９號",
        manufacturer_ShipAddress2: null,
        manufacturer_ShipAddress3: null,
        manufacturer_FactoryNum: null,
        manufacturer_FactoryFax: null,
        manufacturer_AccCode: "6116",
        manufacturer_PayMent: null
    });
    const [request, setRequest] = useState({
        Manufacturer:selectedOption.manufacturer_AbbreviationName,
        Company:'34005573',
        OrderNo:'',
        Model:'',
        QTY:0,
        Date:hahatoday,
        PiNum:''
    });

    const [request2, setRequest2] = useState({
      Manufacturer:selectedOption.manufacturer_AbbreviationName,
      Company:'34005573',
      OrderNo:'',
      DrawNum:'',
      ItemName:'',
      QTY:0,
      Date:hahatoday,
      PiNum:''
    });

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const fetchOptions = async () => {
        try {    
          const response = await axios.get(`${apiUrl}/Manufacturer/list`);
          if (response.status === 200) {
            setOptions(response.data.manufacturers);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const fetchBomOptions = async () => {
      try {    
        const response = await axios.get(`${apiUrl}/OrderManage/Items?drawNum=${drawNum}`);
        if (response.status === 200) {
          setItemOptions(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchOrderList = async () => {
      try {    
        const response = await axios.get(`${apiUrl}/OrderManage/OrderList?drawNum=${filterDrawNum}&piNum=${filterPinum}&orderNo=${filterOrderNo}`);
        if (response.status === 200) {
          setRows(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const handleSave = async () => {
        // Combine edited rows with original rows
        const editedRowsMap = new Map(editedRows.map(row => [row.id, row]));
        const filteredRows = rows.filter(row => row.id !== 0);
        // Combine edited rows with original rows
        const combinedRows = rows.map(row => {
            return editedRowsMap.get(row.id) || row;
        });
        setRows(filteredRows);
        try {
            const response = await axios.post(`${apiUrl}/OrderManage/SaveHandOrder`, combinedRows);
            if (response.status === 200) {     
                setEditedRows([]);
                // Optionally update the rows state with the combinedRows
                setRows(response.data);
            } else {
                alert('修改失敗');
            }
        } catch (error) {
            console.error('Failed to save data:', error);
            alert('修改失敗');
        }
    };

    const handleOldSave = async () => {
      // Combine edited rows with original rows
      const editedRowsMap = new Map(editedRows.map(row => [row.id, row]));
      const filteredRows = rows.filter(row => row.id !== 0);
      // Combine edited rows with original rows
      const combinedRows = rows.map(row => {
          return editedRowsMap.get(row.id) || row;
      });
      setRows(filteredRows);
      try {
          const response = await axios.post(`${apiUrl}/OrderManage/SaveHandOrderOld`, combinedRows);
          if (response.status === 200) {     
              setEditedRows([]);
              // Optionally update the rows state with the combinedRows
              console.log(rows)
              setRows(response.data);
          } else {
              alert('修改失敗');
          }
      } catch (error) {
          console.error('Failed to save data:', error);
          alert('修改失敗');
      }
  };
    
    const processRowUpdate = (newRow, oldRow) => {
        // 透過 newRow 的 id 找到 editedRows 陣列中的索引
        const index = editedRows.findIndex(row => row.id === newRow.id);
      
        // 若找到相同的 id，則先刪除
        if (index > -1) {
          editedRows.splice(index, 1);
        }
      
        // 將 newRow 加入 editedRows 陣列
        setEditedRows([...editedRows, newRow]);
        return newRow;
      };
    const handleItemChange = (event, newValue) => {
        setDrawNum(newValue.圖號)
        setRequest2(prevRequest => ({
          ...prevRequest,
          DrawNum: newValue.圖號,
          ItemName: newValue.物品名
        }))        
        setSelectedItemOption(newValue);
    };

    const handleChange = (event, newValue) => {
        setSelectedOption(newValue);
        if(newValue === null || newValue === undefined) return;
        setRequest((prevRequest) => ({   
            ...prevRequest,
            Manufacturer:newValue.manufacturer_AbbreviationName
        })); 
        setRequest2((prevRequest) => ({   
          ...prevRequest,
          Manufacturer:newValue.manufacturer_AbbreviationName
      })); 
    };


    const handleRequestInputChange = (value, fieldName) => {
        setRequest((prevRequest) => ({
            ...prevRequest,
            [fieldName]: value.target ? value.target.value : value, // conditional check for value
        }));   
        if(fieldName !== 'Model') {
          setRequest2((prevRequest) => ({
            ...prevRequest,
            [fieldName]: value.target ? value.target.value : value, // conditional check for value
        })); 
        }
    };

    const handleSubmit = async () => {   
           
        try {
          const response = await axios.post(`${apiUrl}/OrderManage/HandOrder`, request);
          if (response.status === 200) {
            setRows(response.data);
          }
        } catch (error) {
            console.error("Error logging in:", error);
            alert('失敗 欄位有誤');
        } 
    }

    const handleItemNameSubmit = async () => {   
      try {
        const response = await axios.post(`${apiUrl}/OrderManage/HandOrderByItem`, request2);
        if (response.status === 200) {
          setRows(prevRows => [...prevRows, response.data]);
          handleClose();
        }
      } catch (error) {
          console.error("Error logging in:", error);
          alert('失敗 欄位有誤');
      } 
  }

    useEffect(() => {
        fetchOptions();
    }, []);

    return(
        <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={options}
                sx={{ width: '100%' }}
                value={selectedOption}
                onChange={handleChange}
                getOptionLabel={(option) => option.manufacturer_Seq+ option.manufacturer_AbbreviationName}
                // 設置renderInput來渲染輸入元件
                renderInput={(params) => <TextField {...params} label="廠商" />}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField id="outlined-basic" label="接收日期" value={request.Date} variant="outlined" onChange={(e) => handleRequestInputChange(e.target.value, 'Date')}/>
          </Grid>
          <Grid item xs={2}>
            <TextField id="outlined-basic" label="訂單號碼" value={request.OrderNo} variant="outlined" onChange={(e) => handleRequestInputChange(e.target.value, 'OrderNo')}/>
          </Grid>
          <Grid item xs={2}>
            <TextField id="outlined-basic" label="機種" value={request.Model} variant="outlined" onChange={(e) => handleRequestInputChange(e.target.value, 'Model')}/>
          </Grid>
          <Grid item xs={2} style={{marginTop:'1%'}}>
            <Button variant="outlined" onClick={handleSubmit}>Build</Button>
          </Grid>
          <Grid item xs={2} style={{marginTop:'1%'}}>
            <Button variant="outlined" onClick={handleClickOpen }>用圖號新增</Button>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">承製公司</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={request.Company}
                    label="承製公司"
                    onChange={(e) => handleRequestInputChange(e.target.value, 'Company')}
                >
                    <MenuItem value="34005573">福蒙</MenuItem>
                    <MenuItem value="23032911">華辰</MenuItem>
                </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <TextField id="outlined-basic" label="批號" value={request.PiNum} variant="outlined" onChange={(e) => handleRequestInputChange(e.target.value, 'PiNum')}/>
            </Grid>
            <Grid item xs={2}>
                <TextField id="outlined-basic" label="數量QTY" value={request.QTY} placeholder='請輸入純數字' variant="outlined" 
                onChange={(e) => handleRequestInputChange(e.target.value, 'QTY')}/>
            </Grid>
            <Grid item xs={2}>
                <Button variant="outlined"  onClick={()=>setRows([])}  startIcon={<ClearIcon />}> 
                      重置
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button variant="contained" endIcon={<SaveIcon />}  onClick={handleSave}>
                    保存
                </Button>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={2}>
                <TextField id="outlined-basic" label="批號" value={filterPinum} variant="outlined" onChange={(e) => setFilterPinum(e.target.value)}/>
            </Grid>
            <Grid item xs={2}>
                <TextField id="outlined-basic" label="訂單號碼" value={filterOrderNo} variant="outlined" onChange={(e) => setFilterOrderNo(e.target.value)}/>
            </Grid>
            <Grid item xs={2}>
                <TextField id="outlined-basic" label="圖號" value={filterDrawNum} variant="outlined" onChange={(e) => setFilterDrawNum(e.target.value)}/>
            </Grid>
            <Grid item xs={2}>
                <Button variant="outlined"  onClick={fetchOrderList}> 
                      搜尋
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button variant="contained" endIcon={<SaveIcon />}  onClick={handleOldSave}>
                    舊資料修改
                </Button>
            </Grid>
        </Grid>
        <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 100,
                },
            },
            }}
            pageSizeOptions={[100]}
            disableRowSelectionOnClick
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={error=>alert(error)}
            onRowEditCommit={(rowId, e) => {
              handleSave(rowId,e);
            }}
        />
        </Box>
        <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle>一條一條手輸</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="圖號"
            type="email"
            fullWidth
            variant="standard"
            value={drawNum}
            onChange={(e)=>setDrawNum(e.target.value)}
          />
          <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={itemoptions}
              sx={{ width: '100%' }}
              value={selectedItemOption}
              onChange={handleItemChange}
              getOptionLabel={(option) =>
                `圖號:${option.圖號 || ''},物品名:${option.物品名 || ''}`
              }
              renderInput={(params) => <TextField {...params} label="物品名" />}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button onClick={fetchBomOptions}>搜尋料品表</Button>
          <Button onClick={handleItemNameSubmit}>新增</Button>

        </DialogActions>
      </Dialog>
      </Box>  
      
    )
}