import React, { useEffect, useState } from 'react';

import { 
    Box, 
    FormControl, 
    FormLabel, 
    RadioGroup, 
    FormControlLabel, 
    Radio, 
    TextField, 
    Grid, 
    Button, 
    Checkbox, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    Typography, 
    AppBar, 
    Toolbar, 
    IconButton, 
    Slide, 
    Tooltip, 
    Zoom 
} from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CloseIcon from '@mui/icons-material/Close'; // 將 CloseIcon 移至其他 @mui/icons-material 導入之前

import { 
    Document, 
    Page, 
    Text, 
    StyleSheet, 
    View, 
    Image, 
    PDFDownloadLink, 
    PDFViewer, 
    Font, 
    Svg 
} from '@react-pdf/renderer';

import { useGridApiRef, DataGrid } from '@mui/x-data-grid';

import axios from 'axios';
import JsBarcode from 'jsbarcode';

import { apiUrl } from '../../apiUrl/ApiUrl';

import chFont from '../../../css/font/TaipeiSansTCBeta-Bold.ttf';
import chLight from '../../../css/font/TaipeiSansTCBeta-Light.ttf';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function Inspection({ data }) {
    const [rows, setRows] = useState([]);
    const [print, setPrint] = useState(false);
    const [checkPrint, setCheckPrint] = useState(false);
    const [expectedrows, setExpectedrows] = useState([]);
    const [filter, setFilter] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [open, setOpen] = useState(false);
    const [sendRow, setSendRow] = useState(null);
    const [orderNum, setOrderNum] = useState('');
    const [maxAmount, setMaxAmount] = useState(0);
    const [printItem, setPrintItem] = useState(null);
    const [printItems, setPrintItems] = useState([]);
    const [baseOrder64, setBaseOrder64] = useState('');
    const [basePinum64, setBasePinum64] = useState('');
    const [piNumCounts, setPinumCounts] = useState([]);
    const [dataCleared, setDataCleared] = useState(false);

    // 定義 handlePrintClickOpen 函數
    const handlePrintClickOpen = () => {   
        console.log(printItems); 
        setPrint(true);
    };

    // 定義 handleClose 函數
    const handleClose = () => {
        setPrint(false);
        setCheckPrint(true);
    };

    // 初始化和數據獲取
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/Inspection/GetReserveOrders`);
                if (response.data.reservationOrders != null) {
                    const ordersWithDefaults = response.data.reservationOrders.map(order => ({
                        ...order,
                        
                        expectedAmount: order['未印'], // 預設預交貨數量為未印欄位的值
                        printQuantity: 2 // 預設列印數量為2
                    }));
                    setExpectedrows(ordersWithDefaults);
                } else {
                    alert('數據加載失敗');
                }
            } catch (error) {
                console.error('獲取數據失敗:', error);
                alert('數據加載失敗');
            }
        };

        fetchData();

        // 清空數據在組件掛載時
        setExpectedrows([]);
        setPrintItem(null);
        setPrintItems([]);
    }, []);

    useEffect(() => {
        if (data && data.orders) {
            setRows(data.orders);
        }
    }, [data]);

    useEffect(() => {
        setPinumCounts(countPiNUms(printItems));
    }, [printItems]);

 

    // 計算 piNum 次數
    const countPiNUms = (items) => {
        const flatItems = items.flat();
        const countBypiNum = flatItems.reduce((acc, item) => {
            acc[item.piNum] = (acc[item.piNum] || 0) + 1;
            return acc;
        }, {});

        return Object.entries(countBypiNum).map(([piNum, count]) => ({
            piNum,
            count
        }));
    };

    // 生成條碼
    const generateBarcodes = (value, setValue) => {
        const canvas = document.createElement('canvas');
        JsBarcode(canvas, value, {
            format: 'CODE128',
            displayValue: false,
        });
        setValue(canvas.toDataURL('image/png'));
    };

    // 刪除預交貨訂單
    const handleDeleteReOrder = async(row) => {
        try {
            const response= await axios.post(`${apiUrl}/Inspection/DeleteReserveOrder`, row);
        
            if (response.data.statusCode === 200) {
                alert('成功');
                setExpectedrows(response.data.reservationOrders);
                setRows(response.data.orders);

                const updatedPrintItems = printItems.map(subArray =>
                    subArray.filter(item => item.id !== row.id)
                );

                setPrintItems(updatedPrintItems);
            } else {
                alert('失敗');
            }
        } catch (error) {
            console.error('刪除失敗:', error);
            alert('失敗');
        }
    };

    // 保存所有預交貨訂單
    const SaveAll = async () => {
        try {
            const rowsWithoutPrintQuantity = expectedrows.map(row => {
                const { printQuantity, ...rest } = row;
                return rest;
            });
            console.log(rowsWithoutPrintQuantity);

            const response = await axios.post(`${apiUrl}/Inspection/DeleteAllReserveOrder`, rowsWithoutPrintQuantity );

            if (response.data.statusCode === 200) {
                setExpectedrows([]);
                setRows([]);
                setDataCleared(true);
                alert('資料已清除');
            } else {
                alert('刪除失敗');
            }
        } catch (error) {
            console.error('刪除失敗:', error);
            alert('刪除失敗');
        }
    };

    // 打開添加行的對話框並設置默認值
    const handleDialogOpen = (params, event, details) => {
        const row = params.row;

        const newRow = {
            ...row,
            expectedAmount: row['未印'], // 預設預交貨數量為未印欄位的值
            printQuantity: 2 // 預設列印數量為2
        };

        setSendRow(newRow);
        setExpectedrows(prevRows => [...prevRows, newRow]);
        setRows(prevRows => prevRows.filter(r => r.id !== row.id));
        sendOrder(newRow);
    };

    // 發送訂單到後端
    const sendOrder = async (row) => {
        const data = row || sendRow;

        try {
            const response = await axios.post(`${apiUrl}/Inspection/ReserveOrders`, data);

            if (response.data.statusCode === 200) {
                console.log(response.data);
                setExpectedrows(response.data.reservationOrders);
            } else {
                alert('添加失敗');
            }
        } catch (error) {
            console.error('添加失敗:', error);
            alert('添加失敗');
        }
    };

    // 搜尋訂單
    const serchOrder = async () => {
        let url = '';
        switch (filter) {
            case '批號':
                url = `${apiUrl}/Inspection/GetNewOrders?piNum=${filterValue}`;
                break;
            case '圖號':
                url = `${apiUrl}/Inspection/GetNewOrders?drawNum=${filterValue}`;
                break;
            case '訂單號碼':
                url = `${apiUrl}/Inspection/GetNewOrders?orderNum=${filterValue}`;
                break;
            default:
                url = `${apiUrl}/Inspection/GetNewOrders`;   
        }  

        try {
            const response = await axios.get(url);
            if (response.status === 200) {
                setRows(response.data);
            } else {
                alert('查詢失敗');
            }
        } catch (error) {
            console.error('查詢失敗:', error);
            alert('查詢失敗');
        }
    };

    // 設置列印數量
    const handleSetPrintQuantity = async () => {
        try {
            const updatedRows = await Promise.all(expectedrows.map(async (row) => {
                let order64;
                let piNum64;

                await new Promise((resolve) => {
                    generateBarcodes(row.orderNum + row.orderSerial, (barcode) => {
                        order64 = barcode;
                        resolve();
                    });
                });

                await new Promise((resolve) => {
                    generateBarcodes(row.piNum, (barcode) => {
                        piNum64 = barcode;
                        resolve();
                    });
                });

                return {
                    ...row,
                    order64,
                    piNum64
                };
            }));

            const newPrintItems = [...printItems];

            updatedRows.forEach((row) => {
                const rowCopies = Array(row.printQuantity).fill(row);

                rowCopies.forEach((copy) => {
                    if (newPrintItems.length === 0 || !Array.isArray(newPrintItems[newPrintItems.length - 1])) {
                        newPrintItems.push([copy]);
                    } else if (newPrintItems[newPrintItems.length - 1].length === 6) {
                        newPrintItems.push([copy]);
                    } else {
                        newPrintItems[newPrintItems.length - 1].push(copy);
                    }
                });
            });

            setPrintItem(newPrintItems.flat());
            setPrintItems(newPrintItems);
            console.log('Updated Print Items:', newPrintItems);

        } catch (error) {
            console.error('設置列印數量出錯:', error);
        }
    };

    // 確認列印
    const handleCheckPrint = async(trueOrfalse) => {
        if(trueOrfalse) {
            try {
                const rowsWithoutPrintQuantity = expectedrows.map(row => {
                    const { printQuantity, ...rest } = row;
                    return rest;
                });
                console.log(rowsWithoutPrintQuantity);
                const response = await axios.post(`${apiUrl}/Inspection/ResetReserveOrder`, rowsWithoutPrintQuantity);
                
                if (response.data.statusCode === 200) {
                    alert('成功');
                    setExpectedrows(response.data.reservationOrders);
                    setRows(response.data.orders);
                } else {
                    alert('失敗');
                }
            } catch (error) {
                console.error('重置失敗:', error);
                alert('失敗');
            }
            setCheckPrint(false);
            setPrintItem(null);
        } else {
            setCheckPrint(false);
            setPrintItem(null);
        }
    };

    const columns = [
        { field: '客戶', headerName: '客戶', width: 100, editable: false },
        { field: '批號', headerName: '批號', width: 150, editable: false },
        { field: '訂單號碼', headerName: '訂單號碼', width: 150, editable: false },
        { field: '流水號', headerName: '流水號', sortable: false, width: 60, editable: false },
        { field: '訂購單價', headerName: '訂購單價', type:'number', sortable: true, width: 160, editable: false },
        { field: '訂單數量', headerName: '訂單數量', type:'number', sortable: false, width: 100, editable: false },
        { field: '圖號', headerName: '圖號', sortable: false, width: 160, editable: false },
        { field: '項次', headerName: '項次', sortable: false, width: 60, editable: false },
        { field: '物品名', headerName: '物品名', sortable: false, width: 160, editable: false },
        { field: '承製公司', headerName: '承製公司', sortable: false, width: 160, editable: false },
        { field: '客戶納期', headerName: '客戶納期', sortable: false, width: 160, editable: false },
        { field: '未印', headerName: '未印', sortable: false, type:'number', width: 80, editable: false },
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: '檢票OK',
            headerName: '檢票OK',
            width: 160,
            editable: true,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onChange={() => {
                        const newRows = rows.map(row => {
                            if (row.id === params.id) {
                                return { ...row, inspectionOK: !params.value };
                            }
                            return row;
                        });
                        setRows(newRows);
                    }}
                />
            ),
        },
    ];

    const detailColumns = [
        {
            field: 'delete',
            headerName: '刪除',
            width: 60,
            sortable: false,
            editable: false,
            renderCell: (params) => (
                <Button startIcon={<DeleteOutlinedIcon />} onClick={() => handleDeleteReOrder(params.row)}>
                    ..
                </Button>
            ),
        },
        { field: 'piNum', headerName: '批號', width: 100, editable: false },
        { field: 'orderNum', headerName: '訂單號碼', width: 100, editable: false },
        { field: 'model', headerName: '機種', width: 100, editable: false },
        { field: 'drawNum', headerName: '圖號', sortable: false, width: 105, editable: false },
        { field: 'itemName', headerName: '品名', sortable: true, width: 120, editable: false },
        { field: 'orderAmount', headerName: '訂單量', sortable: false, width: 70, editable: false },
        {
            field: 'expectedAmount',
            headerName: '預交貨量',
            sortable: false,
            width: 90,
            editable: true,
            renderCell: (params) => (
                <TextField
                    type="number"
                    size="small"
                    fullWidth
                    value={params.value || 0}
                    onChange={(event) => {
                        const value = parseInt(event.target.value, 10);
                        const updatedRows = expectedrows.map(row => {
                            if (row.id === params.row.id) {
                                return { ...row, expectedAmount: value };
                            }
                            return row;
                        });
                        setExpectedrows(updatedRows);
                    }}
                    inputProps={{ min: 0 }}
                />
            )
        },
        {
            field: 'printQuantity',
            headerName: '列印数量',
            width: 110,
            editable: true,
            type: 'number',
            renderCell: (params) => (
                <TextField
                    type="number"
                    size="small"
                    fullWidth
                    value={params.value || 2}
                    onChange={(event) => {
                        const value = parseInt(event.target.value, 10);
                        const updatedRows = expectedrows.map(row => {
                            if (row.id === params.row.id) {
                                return { ...row, printQuantity: value };
                            }
                            return row;
                        });
                        setExpectedrows(updatedRows);
                    }}
                    inputProps={{ min: 0 }}
                />
            )
        },
        { field: 'expectedTradeDate', headerName: '交貨日期', sortable: false, width: 100, editable: false },
        { field: 'id', headerName: 'ID', width: 90 }
    ];

    return (
        <Box m={2}>
            <Grid container alignItems="center" spacing={0}>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel id="demo-row-radio-buttons-group-label">過濾條件</FormLabel>                
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => setFilter(e.target.value)}
                        >
                            <FormControlLabel value="批號" control={<Radio />} label="批號" />
                            <FormControlLabel value="圖號" control={<Radio />} label="圖號" />
                            <FormControlLabel value="訂單號碼" control={<Radio />} label="訂單號碼" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Required"
                        defaultValue=""
                        size="small"
                        onChange={(e) => setFilterValue(e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={serchOrder}>
                        搜尋
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    {printItem && (
                        <>
                            <img src={baseOrder64} alt="Order Barcode" />
                            <img src={basePinum64} alt="PiNum Barcode" />
                        </>
                    )}
                </Grid>
            </Grid>
            <Box sx={{ height: 600, width: '90%'}}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 100 },
                        },
                    }}
                    onRowDoubleClick={handleDialogOpen}
                    pageSizeOptions={[50,100]}
                    disableRowSelectionOnClick
                />
                <Typography variant="h3">預交貨訂單</Typography>
                <Tooltip TransitionComponent={Zoom} title="Save">
                    <Button onClick={SaveAll}>清除列印資料</Button>
                    <Button onClick={handlePrintClickOpen}>列印現品票</Button>
                    <Button onClick={() => setPrintItems([])}>重設列印數量</Button>
                    <Button onClick={handleSetPrintQuantity}>設定列印數量</Button>
                </Tooltip>
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'left', backgroundColor: '#f4f4f4' }}>批號</th>
                            <th style={{ textAlign: 'left', backgroundColor: '#f4f4f4' }}>次數</th>
                        </tr>
                    </thead>
                    <tbody>
                        {piNumCounts.map((item, index) => (
                            <tr key={index}>
                                <td style={{ padding: '8px 20px', border: '1px solid #ddd' }}>{item.piNum}</td>
                                <td style={{ padding: '8px 20px', border: '1px solid #ddd' }}>{item.count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <DataGrid
                    rows={expectedrows}
                    columns={detailColumns}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 100 },
                        },
                    }}
                    onRowDoubleClick={handleDialogOpen}
                    pageSizeOptions={[100,400]}
                    disableRowSelectionOnClick
                />
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>預交貨訂單數量確認</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            訂單號碼{orderNum}預交貨數量
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="數量"
                            type="number" // 修改類型為 number
                            fullWidth
                            variant="standard"
                            defaultValue={maxAmount}
                            onChange={(e) => setMaxAmount(parseInt(e.target.value, 10))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>取消</Button>
                        <Button onClick={sendOrder}>送出</Button>
                    </DialogActions>
                </Dialog> 
                <Dialog
                    fullScreen
                    open={print}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                列印預覽
                            </Typography>
                        </Toolbar>             
                    </AppBar>         
                    <PDFViewer style={{ width: '100%', height: '100%' }}>
                        <MyDocument datas={printItems} />
                    </PDFViewer>
                </Dialog> 
                <Dialog
                    open={checkPrint}
                    onClose={() => handleCheckPrint(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"請問您剛剛是否有列印?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            若有列印 請選擇是，會將該筆預交貨訂單刪除 並更改資料庫<br/>
                            若無列印 請選擇否，資料庫保持原樣
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleCheckPrint(true)}>已列印</Button>
                        <Button onClick={() => handleCheckPrint(false)} autoFocus>
                            未列印
                        </Button>
                    </DialogActions>
                </Dialog>  
            </Box>
        </Box>
    );
}

Font.register({
    family: 'TaipeiSansTCBeta-Bold',
    src: chFont,
});
Font.register({
    family: 'TaipeiSansTCBeta-Light',
    src: chLight,
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 10,
        backgroundColor: 'white',
    },
    gridContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    gridTitle: {
        flexGrow: 0,
        fontFamily: 'TaipeiSansTCBeta-Bold',
        marginLeft:'30%',
        fontSize: 14,
        marginBottom: 10
    },
    gridItem1: {
        flexGrow: 1,
        fontSize: 12,
        padding: 0,
        marginBottom: 10,
        fontFamily: 'TaipeiSansTCBeta-Bold',
    },
    gridItem2: {
        flexGrow: 1,
        fontSize: 12,
        padding: 0,
        marginBottom: 10,
        fontFamily: 'TaipeiSansTCBeta-Light',
    },
    gridImg: {
        flexGrow: 1,
        width: '50%', 
        height: '50%', 
        marginTop:5,
        marginBottom:5
    },
    section: {
        width: '50%',
        height: '33.33%',
        padding: 10,
        fontFamily: 'TaipeiSansTCBeta-Bold',
    },
});

const MyDocument = ({ datas }) => {
    console.log(datas); 
    if (!datas || datas.length === 0) {
        return <Text>沒有數據可顯示</Text>;
    }

    const today = new Date();
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    };

    return (
        <Document>       
            {datas.map((details, pageIndex) => (
                <Page size="A4" style={styles.page} key={pageIndex}>
                    {details.map((data, index) => (
                        <View style={styles.section} key={index}>
                            <View style={styles.gridTitle}>
                                <Text>台灣日立公司交貨現品票</Text>
                            </View>
                            <View style={styles.gridContainer}>
                                <View style={styles.gridItem1}>
                                    <Text>廠商名稱:</Text>
                                </View>
                                <View style={styles.gridItem2}>
                                    <Text>華辰企業股份公司</Text>
                                </View>
                                <View style={styles.gridItem1}>
                                    <Text>交貨日期:</Text>
                                </View>
                                <View style={styles.gridItem2}>
                                    <Text>{formatDate(today)}</Text>
                                </View>
                            </View> 
                            <View style={styles.gridContainer}>
                                <View style={styles.gridItem1}>
                                    <Text>料件:</Text>
                                </View>
                                <View style={styles.gridItem2}>
                                    <Text>{data.drawNum}</Text>
                                </View>
                                <View style={styles.gridItem1}>
                                    <Text>物品名:</Text>
                                </View>
                                <View style={styles.gridItem2}>
                                    <Text>{data.itemName}</Text>
                                </View>
                            </View>
                            <View style={styles.gridContainer}>
                                <View style={styles.gridItem1}>
                                    <Text>訂單數量:</Text>
                                </View>
                                <View style={styles.gridItem2}>
                                    <Text>{data.orderAmount}</Text>
                                </View>
                                <View style={styles.gridItem1}>
                                    <Text>交貨數量:</Text>
                                </View>
                                <View style={styles.gridItem2}>
                                    <Text>{data.expectedAmount}</Text>
                                </View>
                            </View>
                            <View style={styles.gridContainer}>
                                <View style={styles.gridItem1}>
                                    <Text>英文品名:</Text>
                                </View>
                                <View style={styles.gridItem2}>
                                    <Text>BASE</Text>
                                </View>
                                <View style={styles.gridItem1}>
                                    <Text>倉庫:</Text>
                                </View>
                                <View style={styles.gridItem2}>
                                    <Text>36525B</Text>
                                </View>
                            </View>
                            <View style={styles.gridContainer}>
                                <View style={styles.gridItem1}>
                                    <Text>收貨中心:</Text>
                                </View>
                                <View style={styles.gridItem2}>
                                    <Text>Q1</Text>
                                </View>
                                <View style={styles.gridItem1}>
                                    <Text>現場位置:</Text>
                                </View>
                                <View style={styles.gridItem2}>
                                    <Text>1C1</Text>
                                </View>
                            </View>
                            <View style={styles.gridContainer}>
                                <View style={styles.gridItem1}>
                                    <Text>訂單號碼:</Text>
                                </View>
                                <View style={styles.gridImg}>
                                    <Image src={data.order64} />
                                    <Text style={styles.barcodeText}>{data.orderNum}</Text>     
                                </View>
                            </View>
                            <View style={styles.gridContainer}>
                                <View style={styles.gridItem1}>
                                    <Text>使用工單:</Text>
                                </View>
                                <View style={styles.gridImg}>                            
                                    <Image src={data.piNum64} />
                                    <Text style={styles.barcodeText}>{data.piNum}</Text>      
                                </View>
                            </View>
                            <View style={styles.gridContainer}>
                                <View style={styles.gridItem1}>
                                    <Text>存放位置:</Text>
                                </View>
                                <View style={styles.gridItem1}>
                                    <Text>1C1</Text>
                                </View>
                            </View>
                            <View style={styles.gridContainer}>
                                <View style={styles.gridItem1}>
                                    <Text>使用專案:</Text>
                                </View>
                                <View style={styles.gridItem2}>
                                    <Text>免檢</Text>
                                </View>
                            </View>
                        </View>
                    ))}
                </Page>
            ))}            
        </Document>
    );
};
