import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import { useNavigate,useParams  } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import ReplyIcon from '@mui/icons-material/Reply';
import InputLabel from '@mui/material/InputLabel';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SendIcon from '@mui/icons-material/Send';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchIcon from '@mui/icons-material/Search';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import NativeSelect from '@mui/material/NativeSelect';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Document, Page, Text, StyleSheet, View, Image,PDFDownloadLink ,PDFViewer ,Font ,Svg, pdf } from '@react-pdf/renderer';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import '../../../css/matset.css'
import { apiUrl } from '../../apiUrl/ApiUrl';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function BuyWithToday({data}) {
    const [newData,setNewData] = useState(null);
    const [selected, setSelected] = useState('');
    const [detailData,setDetailData] = useState(null);
    const [model,setModel] = useState('讀取模式');
    const [editData,setEditData] = useState(null);

    const [piNum,setPiNum] = useState('');
    const [needDate,setNeedDate] = useState('');
    const [buyDate,setBuyDate] = useState('');
    const [orderNum,setOrderNum] = useState('');
    const [approveDate,setApproveDate] = useState('');

    console.log(data)
    const [page,setPage] = useState(1);
    // console.log(data)
    const workMargin = {
        marginTop: '3%',
        marginBottom:'5%'
    } 


    const setModelandGetDate = (newModel) =>{
        setModel(newModel);
    }

    return(
        <div className='container-fluid'>
            <div className='row'  style={workMargin}>
                <div className='col-5'/>
                <div className='col-2'>
                    <h2><span className="badge bg-success">列印請採購</span></h2>
                </div>
                <div className='col-5'/>              
            </div>
            <div className='row' style={workMargin}>
                <div className='col-3'>
                    <ChooseModel setModel={setModel} setNewData={setNewData} setSelected={setSelected}/>
                </div>
                <div className='col-5'>
                    {model==='搜尋模式'?<SelectFilter setNewData={setNewData}                    
                    setBuyDate={setBuyDate} 
                    setPiNum={setPiNum}
                    setNeedDate={setNeedDate}
                    setApproveDate={setApproveDate}
                    setOrderNum={setOrderNum}
                    buyDate={buyDate}
                    needDate={needDate}
                    piNum={piNum}
                    orderNum={orderNum}
                    approveDate={approveDate} 
                    />:null}
                    
                </div>
                <div className='col-2'/>
            </div>
            <div className='row'>
                {/* <div className='col-12'>
                    <h2><span className="badge bg-dark">生管資材卡</span></h2>
                </div>               */}
            </div>
            <div className='row mb-3'>
                <div className='col-12'>
                    {/* {detailData != null ? <DetailListTable data={detailData} 
                    setDetailData={setDetailData} 
                    setModel={setModel}
                    setEditData={setEditData}/> : <div>目前沒有副表資料</div>} */}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">採購資料</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'/>
            </div>
            <div className='row'>
                <div className='col-12'>
                    {data != null?<ListTable setDetailData={setDetailData} 
                    setNewData={setNewData} data={newData || data} 
                    setModel={setModelandGetDate} page={page} 
                    setPage={setPage}
                    buyDate={buyDate}
                    needDate={needDate}
                    piNum={piNum} /> :<div>目前還沒取得資料或取不到資料</div>}
                </div>              
            </div>
        </div>     
    )
}

function ListTable({data,setNewData,needDate,setDetailData,page,setPage,piNum,buyDate}) {
    const [open, setOpen] = React.useState(false);
    const [pdfNoOpen, setPdfNoOpen] = React.useState(false);
    const [from, setFrom] = React.useState('');
    const [to, setTo] = React.useState('');
    const [pdfOpen, setPdfOpen] = React.useState(false);
    const [printRows, setPrintRows] = React.useState([]);
    const [orderNumArr, setOrderNumArr] = React.useState([]);
    const [idArr, setIdArr] = React.useState([]);

    const handlePdfClickOpen = () => {
        setPdfOpen(true);
    };
    const handlePdfClickClose = () => {
        setPdfOpen(false);
    };

    const handlePdfNoClickOpen = () => {
        setPdfNoOpen(true);
    };

    const handlePdfNoClose = () => {
        setPdfNoOpen(false);
    };
    
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const thStyle = {
        centered: {
            textAlign: 'center',
          }
    }
    const btnPageMargin = {
        marginLeft: '2%',
        marginRight: '2%',
    }


    const nextPage = () => {
            axios.get(`${apiUrl}/Buyer/GetMains?needDate=${needDate}&piNum=${piNum}&buyDate=${buyDate}&orderNum=${''}&page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        
    }

    const prePage = () => {
            axios.get(`${apiUrl}/Buyer/GetMains?needDate=${needDate}&piNum=${piNum}&buyDate=${buyDate}&orderNum=${''}&page=${page-1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        
    }


    const GetDetails = (drawNum) => {
        axios.get(`${apiUrl}/Buyer/GetDetails?drawNum=${drawNum}`)
        .then(response => {
            if(response.data.statusCode === 200) {
                setDetailData(response.data)
                console.log(response.data)
            }else{
                alert('找不到資料');
            }            
        })
        .catch(error =>{
            // handle error
            console.log(error);
        })
    }

    const dataToExcel = () => {
        axios.get(`${apiUrl}/Buyer/DataToExcel`, {responseType: 'blob'})
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '請採購資料.xlsx'); // or any other filename you want
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => console.log(error));
    }

    const handlePrintClick = async () => {
        if (from === null || to === null) {
            alert("from 和 to 参数不得为 null");
            // 这里可以替换为更适合的错误处理方式，比如弹出提示框
            return; // 提前结束函数执行
        }
        try {    
            const response = await axios.get(`${apiUrl}/Buyer/DataToPdf?from=${from}&to=${to}`);
            if (response.status === 200 && response.data.length > 0) {             
                console.log(response.data);
                setPrintRows(response.data);
        
                // 提取所有不重复的单号并存储第一次出现的物件的Id
                const uniqueOrderNumbers = new Set();
                const firstOccurrenceIds = [];
        
                // 记录单号和对应的第一次出现的物件Id
                response.data.forEach(item => {
                    if (!uniqueOrderNumbers.has(item.單號)) {
                        uniqueOrderNumbers.add(item.單號);
                        firstOccurrenceIds.push(item.id); // 假设物件的Id属性名为"Id"
                    }
                });
        
                // 如果需要，将不重复的单号数组也保存下来
                const uniqueOrderNumbersArray = Array.from(uniqueOrderNumbers);
                setOrderNumArr(uniqueOrderNumbersArray);
        
                // 将找到的Id数组保存起来
                setIdArr(firstOccurrenceIds); // 假设你有一个名为setIdArray的函数用于保存Id数组

                // 將資料傳遞給後端，並更新列印狀態
                await updatePrintStatusToOk(uniqueOrderNumbersArray);
        
                handlePdfClickOpen();
            } else {
                alert('找無資料 請重新輸入單號區間');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        
    };

    // 更新列印狀態的函數
    const updatePrintStatusToOk = async (orderNumbers) => {
        try {
            const response = await axios.post(`${apiUrl}/Buyer/UpdatePrintStatus`, {
                orderNumbers, // 使用屬性縮寫
            });
            if (response.status === 200) {
                console.log('列印狀態更新成功');
            } else {
                console.log('列印狀態更新失敗');
            }
        } catch (error) {
            console.error('Error updating print status:', error);
        }
    };
    
    
    const dataToExcelRange = () => {
        if (from === null || to === null) {
            alert("from 和 to 参数不得为 null");
            // 这里可以替换为更适合的错误处理方式，比如弹出提示框
            return; // 提前结束函数执行
        }
    
        axios.get(`${apiUrl}/Buyer/DataToExcelRange?from=${from}&to=${to}`, {responseType: 'blob'})
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '請採購資料.xlsx'); // or any other filename you want
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => console.log(error));
    }
    

    return(
        <>
        <div style={{overflowX: "auto", height: "80vh"}}>        
            <button type="button" style={btnPageMargin} className="btn btn-secondary" onClick={() => dataToExcel()}>列印Excel</button>    
            <button type="button" style={btnPageMargin} className="btn btn-secondary" onClick={handleClickOpen}>範圍列印Excel</button>    
            <button type="button" style={btnPageMargin} className="btn btn-secondary" onClick={handlePdfNoClickOpen}>範圍列印PDF</button>    
            <table className="table">
                <thead className="sticky-header">
                    <tr>
                        <th scope="col" style={thStyle.centered}>#</th>
                        <th scope="col" style={thStyle.centered}>採購日期</th>
                        <th scope="col" style={thStyle.centered}>廠商</th>
                        <th scope="col" style={thStyle.centered}>需求日</th>
                        <th scope="col" style={thStyle.centered}>圖號</th>
                        <th scope="col" style={thStyle.centered}>物品名</th>
                        <th scope="col" style={thStyle.centered}>單位</th>
                        <th scope="col" style={thStyle.centered}>數量</th>
                        <th scope="col" style={thStyle.centered}>單價</th>
                        <th scope="col" style={thStyle.centered}>金額</th>
                        <th scope="col" style={thStyle.centered}>單號</th>
                        <th scope="col" style={thStyle.centered}>批號</th>
                        <th scope="col" style={thStyle.centered}>核准</th>
                    </tr>
                </thead>
                {data.buyerDtos.length > 0 ?<tbody>
                    {data.buyerDtos.map((item, index) => (
                        <tr key={uuidv4()}>
                            <th scope="row" style={thStyle.centered}>{index + 1}</th>
                            <td style={thStyle.centered}>{item.date}</td>
                            <td style={thStyle.centered}>{item.manufacturer}</td>
                            <td style={thStyle.centered}>{item.needDate}</td>  
                            <td style={thStyle.centered}>{item.drawNum}</td> 
                            <td style={thStyle.centered}>{item.itemName}</td> 
                            <td style={thStyle.centered}>{item.unit}</td> 
                            <td style={thStyle.centered}>{item.amount}</td> 
                            <td style={thStyle.centered}>{item.unitPrice}</td> 
                            <td style={thStyle.centered}>{item.moneyAmount}</td> 
                            <td style={thStyle.centered}>{item.orderNum}</td> 
                            <td style={thStyle.centered}>{item.piNum}</td> 
                            <td style={thStyle.centered}>{item.approve}</td>             
                        </tr>
                    ))}
                </tbody>:null}
            </table>
            <Dialog
                fullScreen
                open={pdfOpen}
                onClose={handlePdfClickClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handlePdfClickClose}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                    <Button autoFocus color="inherit" onClick={handlePdfClickClose}>
                    列印預覽
                    </Button>
                </Toolbar>
                </AppBar>
                {printRows.length > 0 ?                 
                <PDFViewer style={{ width: '100%', height: '100%' }}>
                    <MyBomDocument data={printRows} com={'福蒙'} 
                    orderNum={orderNumArr}
                    client={printRows[0].廠商}
                    id={idArr}
                    day={printRows[0].需求日}/>
                </PDFViewer> : null }

            </Dialog>
        </div>
        <Dialog open={open} onClose={handleClose}>
                <DialogTitle>列印單號</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="從"
                    type="email"
                    fullWidth
                    value={from}
                    onChange={(e)=>setFrom(e.target.value)}
                    variant="standard"
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="到"
                    type="email"
                    fullWidth
                    value={to}
                    onChange={(e)=>setTo(e.target.value)}
                    variant="standard"
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>取消</Button>
                <Button onClick={dataToExcelRange}>送出</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={pdfNoOpen} onClose={handlePdfNoClose}>
                <DialogTitle>列印單號</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="從"
                    type="email"
                    fullWidth
                    value={from}
                    onChange={(e)=>setFrom(e.target.value)}
                    variant="standard"
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="到"
                    type="email"
                    fullWidth
                    value={to}
                    onChange={(e)=>setTo(e.target.value)}
                    variant="standard"
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handlePdfNoClose}>取消</Button>
                <Button onClick={handlePrintClick}>送出</Button>
                </DialogActions>
            </Dialog>
        {/* <div style={{textAlign: 'center'}} className='mt-3'>
            <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => prePage()} disabled={page === 1}>上一頁</button>
                <span style={{margin: '0 10px'}}>目前在第{page}頁</span>
            <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => nextPage()}>下一頁</button>
        </div> */}
        </>
    )
}
function DetailListTable({ data,setDetailData,setModel,setEditData }) {
    const btnMargin = {
        marginLeft: '2%',
    };
    const thStyle = {
        centered: {
        textAlign: 'center',
        },
    };

    const handleEdit = (detail) => {
        setModel('採購入庫修改');
        setEditData(detail);
        console.log(detail)
    };

    const handleDelete = (detail) => {
        // 建立要傳送的物件
        const Data = {
        drawNum: detail.drawNum,
        company: detail.company,
        date: detail.date,
        pk: detail.pk,
        price: detail.price,
        pricePerson: detail.pricePerson,
        };

        // 發送 POST 請求到後端 API
        axios
        .post(`${apiUrl}/PurchasePrice/delete/DeleteDetail`, Data)
        .then((response) => {
            // 處理刪除成功的回應console.log(response.data)
            if (response.data === 200) {
                axios
                    .get(`${apiUrl}/PurchasePrice/DetailList?drawNum=${Data.drawNum}`)
                    .then(response => {
                        // Update the data
                        setDetailData(response.data);
                        alert('刪除成功');
                    })
                    .catch(error => {
                        // handle error
                        console.log(error);
                    });
            } else {
                alert('刪除失敗，找不到可刪除物件');
            }
        })
        .catch((error) => {
            // 處理刪除失敗的回應
            alert('刪除失敗，找不到可刪除物件')
            console.error(error);
        });
    };

    return (
        <div style={{overflowX: "auto", height: "50vh"}}>              
            <table className="table">
                <thead className="sticky-header">
                    <tr>
                    <th scope="col" style={thStyle.centered}>
                        #
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        日期
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        類別
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        單號
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        批號
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        入量
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        出量
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        庫存量
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        核准
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        領用
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        操作
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {data.purchaseMatCardDtos.map((detail, index) => (
                    <tr key={uuidv4()}>
                        <th scope="row" style={thStyle.centered}>
                        {index + 1}
                        </th>
                        <td style={thStyle.centered}>{detail.date}</td>
                        <td style={thStyle.centered}>{detail.kind}</td>
                        <td style={thStyle.centered}>{detail.orderNum}</td>
                        <td style={thStyle.centered}>{detail.piNum}</td>
                        <td style={thStyle.centered}>{detail.intAmount}</td>
                        <td style={thStyle.centered}>{detail.outAmount}</td>
                        <td style={thStyle.centered}>{detail.stockAmount}</td>
                        <td style={thStyle.centered}>{detail.approve}</td>
                        <td style={thStyle.centered}>{detail.receive}</td>
                        <td style={thStyle.centered}>
                        <button type="button" className="btn btn-warning" onClick={() => handleEdit(detail)}
                        >修改</button>  
                        </td> 
                    </tr>
                    ))}
                    {/* disabled={!!detail.invoiceNum} */}
                </tbody>
            </table>
        </div>
    );
}
function ChooseModel({ setModel,selected,setSelected}) { 
    const handleChange = (event) => {
        setSelected(event.target.id);
    };
    const selectModel = (event) => {
        if (event.target.id === "btnradio2") {
            setModel('搜尋模式')
        }
        else if (event.target.id === "btnradio4") {
            setModel('讀取模式')
        }
    }
        
    return (
        <div className="btn-group-vertical">
            <label className={selected === 'btnradio2' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio2">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio2"
                    checked={selected === 'btnradio2'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>搜尋模式</strong>
            </label>
            <label className={selected === 'btnradio4' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio4">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio4"
                    checked={selected === 'btnradio4'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>讀取模式</strong>
            </label>
        </div>
    );
}

function CreateForm({editData}) {

    const [formData, setFormData] = useState({
        manufacturers: '祥達'
        // 更多的字段...
    });
    const [options, setOptions] = useState([]);
    const [selectValue, setSelectValue] = useState("");

    useEffect(() => {
        setFormData(editData || {
            manufacturers: '祥達'
            // 更多的字段...
        });
    }, [editData]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/Storage/GetOptions`);
                setOptions(response.data);
            } catch (error) {
                console.error('Failed to fetch data', error);
            }
        };

        fetchData();
    }, []);  
    console.log(editData)

    const handleInsertSubmit = async (event) => {
        event.preventDefault();
        if(formData.manufacturers === '') {
            formData.manufacturers='祥達'
        }else{
            formData.manufacturers = selectValue;
        }
        
        if(editData === null) {
            try {
                // 使用你的API URL
                const response = await axios.post(`${apiUrl}/Storage/CreateMain`, formData);
                    if (response.data.statusCode === 200) {
                        // 处理响应
                        console.log('Data successfully submitted');
                    }else{
                        alert('新增失敗 請確認欄位值')
                    }
                } 
                catch (error) {
                console.error('There was an error!', error);
                }
        }else {
            try {
                // 使用你的API URL
                const response = await axios.post(`${apiUrl}/Storage/EditMain`, formData);
                    if (response.data.statusCode === 200) {
                        // 处理响应
                        console.log('Data successfully submitted');
                    }else{
                        alert('修改失敗 請確認欄位值')
                    }
                } 
                catch (error) {
                console.error('There was an error!', error);
                }
        }

        

    };


    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));       
    };
    const handleSelectChange = (event) => {
        setSelectValue(event.target.value);
    }
    return(
        <form onSubmit={handleInsertSubmit}>
                        <div className='container-fluid'>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>入庫日期</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="date" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.date : ''}
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>採購單號</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="orderNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.orderNum : ''}
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>廠商</span>
                                </div>
                                <div className='col-2 ps-0'>
                                <select className="form-select" id="manufacturers" value={editData?editData.manufacturers:selectValue} onChange={handleSelectChange}>
                                {options.companyOptions != null?options.companyOptions.map((item, index) => (
                                    <option key={index} value={item.comSimpleName}>
                                        {`${item.comNum}  ${item.comSimpleName}  ${item.comFullName}`}
                                    </option>
                                )):null}                                
                                </select>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>對帳月份</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="reconciliationMonth" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.reconciliationMonth : ''}
                                    onChange={handleInputChange}/>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>入庫類別</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="kind" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.kind : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>發票號碼</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="invoiceNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.invoiceNum : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>序號</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="order" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.order : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>圖號</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="drawNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.drawNum : ''}
                                    onChange={handleInputChange}/>
                                </div>                             
                            </div>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>物品名</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="itemName" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.itemName : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>單位</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="unit" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.unit : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>數量</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="amount" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.amount : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>單價</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="unitPrice" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.unitPrice : ''}
                                    onChange={handleInputChange}/>
                                </div>                             
                            </div>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>金額</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="price" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.price : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>批號</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="piNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.piNum : ''}
                                    onChange={handleInputChange}/>
                                </div>                              
                            </div>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                    <button type="submit" className="btn btn-dark">提交</button>
                                </div>
                            </div>
                        </div>
                    </form>
    )
}
function SelectFilter({setNewData,piNum,setPiNum,needDate,setNeedDate,buyDate,setBuyDate,orderNum,setOrderNum,approveDate,setApproveDate}) {


    const handleSubmit = async (event) => {
        event.preventDefault();
        // Replace with your actual endpoint
        const response = await axios.get(`${apiUrl}/Buyer/GetMainsWithNull?needDate=${needDate}&piNum=${piNum}&buyDate=${buyDate}&orderNum=${orderNum}&page=1`);
        if(response.data.buyerDtos != null) {
            setNewData(response.data)
        }else {
            alert('找不到資料')
        }
        
        console.log(response.data)
    };

    const clearSubmit = async (event) => {

        // Replace with your actual endpoint
        const response = await axios.get(`${apiUrl}/Buyer/GetMainsWithNull?page=1`);
            setNewData(response.data)
            setPiNum('');
            setNeedDate('');
            setBuyDate('');
            setOrderNum('');
            setApproveDate('');
        console.log(response.data)
    };
    return(
        <form onSubmit={handleSubmit}>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-4'>
                    <label htmlFor="piNum">批號
                    <input type="text"  className="form-control" id="piNum" value={piNum} onChange={(e) => setPiNum(e.target.value)}/>
                    </label>
                </div>
                <div className='col-4'>
                    <label htmlFor="buyDate">採購日期
                    <input type="text" placeholder='yyyy-mm-dd'  className="form-control" id="buyDate" value={buyDate} onChange={(e) => setBuyDate(e.target.value)}/>
                    </label>
                </div>
                <div className='col-4'>
                    <label htmlFor="needDate">需求日期
                    <input type="text" placeholder='yyyy-mm-dd'  className="form-control" id="needDate" value={needDate} onChange={(e) => setNeedDate(e.target.value)}/>
                    </label>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-4'>
                    <label htmlFor="piNum">單號
                    <input type="text"  className="form-control" id="piNum" value={orderNum} onChange={(e) => setOrderNum(e.target.value)}/>
                    </label>
                </div>
                {/* <div className='col-4'>
                    <label htmlFor="buyDate">核准日期
                    <input type="text" placeholder='yyyy-mm-dd'  className="form-control" id="buyDate" value={approveDate} onChange={(e) => setApproveDate(e.target.value)}/>
                    </label>
                </div> */}
            </div>
            <div className='row' style={{ textAlign: 'center' }}>
                <div className='col-12'>
                    <button type="submit" className="btn btn-dark" style={{margin:'20px'}}>搜尋</button>
                    <button type="button" className="btn btn-dark" onClick={()=>clearSubmit()}>清除搜尋條件</button>
                </div>
            </div>
        </div>      
  </form>
    )
}

Font.register({
    family: 'TaipeiSansTCBeta-Bold',
    src: 'path/to/TaipeiSansTCBeta-Bold.ttf', // 替换为您的实际字体文件路径
  });
  Font.register({
    family: 'TaipeiSansTCBeta-Light',
    src: 'path/to/TaipeiSansTCBeta-Light.ttf', // 替换为您的实际字体文件路径
  });
  // 页面宽高转换为点（1cm = 28.28点）
  const pageWidth = 24.1 * 72 / 2.54;
  const pageHeight = 14 * 72 / 2.54;
  
  // 表格宽度比例，根据您的表格设计调整
  const cellWidth = pageWidth / 6; // 假设有8列
  

  const BOMstyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 24,
        width: pageWidth,
        height: pageHeight,
    },
    onePage: {
        flexDirection: 'row',
        backgroundColor: 'white',
        padding: 24,
        width: pageWidth,
        height: pageHeight,
    },
    title: {
        fontSize: 20,
        textAlign: 'center',
        fontFamily: 'TaipeiSansTCBeta-Bold',
        marginBottom: 10,
    },
    tableContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        fontFamily: 'TaipeiSansTCBeta-Bold', // 新增此行以确保容器内的内容（标题）居中
        width: '100%', // 确保容器宽度充满整个页面或其父容器
    },  
    headerRow: {
      flexDirection: 'row',
      width: '100%', // 确保行宽充满整个页面
      textAlign: 'center', 
      fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
      marginTop: 5, // 根据需要调整，为标题和标头之间提供一些空间
    },
    headerBottomRow: {
        flexDirection: 'row',
        width: '100%', // 确保行宽充满整个页面
        textAlign: 'center', 
        fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
        marginTop: 100, // 根据需要调整，为标题和标头之间提供一些空间
      },
    headerRowDash: {
      flexDirection: 'row',
      width: '100%', // 确保行宽充满整个页面
      textAlign: 'center', 
      fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
      marginTop: 10, // 标题和标头之间的空间
      borderTop: 1, // 添加上边框
      borderBottom: 1, // 添加下边框
      borderColor: 'black', // 边框颜色
      paddingTop: 5, // 标头内容与上边框之间的空间
      paddingBottom: 5, // 标头内容与下边框之间的空间
    },
    headerItem: {
        flex: 1, // 平分容器宽度
        textAlign: 'center', // 文本居中
        fontFamily: 'TaipeiSansTCBeta-Light',
        fontSize: 10,
    },
    header子件料號: {
      width: '15%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header品名: {
      width: '5%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header規格: {
      width: '25%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header配賦量: {
      width: '5%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header單位: {
      width: '10%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header需求量: {
      width: '10%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header實領: {
      width: '10%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header備註: {
      width: '20%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
});
  const currentDate = new Date();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份需要加1，因为它从0开始
  const currentDay = String(currentDate.getDate()).padStart(2, '0');
  const dateString = `${currentDate.getFullYear()}/${currentMonth}/${currentDay}`;
  // 创建文档组件
  const MyBomDocument = ({ data,com,client,day,id ,orderNum}) => ( 
    <Document>
            {orderNum.map((idItem, idIndex) => (
                <>
                <Page size={{ width: pageWidth, height: pageHeight }} style={BOMstyles.page}>
                    <View style={BOMstyles.tableContainer}>
                        <Text style={BOMstyles.title}>{com} 請採購驗收單</Text>
                    </View>
                    <View style={BOMstyles.headerRow}>
                        <Text style={BOMstyles.headerItem}>月份選擇:{day.split('T')[0]}</Text>
                        <Text style={BOMstyles.headerItem}>日期:{dateString}</Text>
                        <Text style={BOMstyles.headerItem}>單號{idItem}</Text>
                    </View>
                {data.map((item, index) => (
                    <>
                        {idItem === item.單號 ? 
                        <>
                            {id.includes(item.id) ?
                                <>
                                    <View style={BOMstyles.headerRow}>
                                        <Text style={BOMstyles.headerItem}>公司:{item.公司}</Text>
                                    </View>
                                </> : null
                            }
                            <View style={BOMstyles.headerRow} key={index}>
                                <Text style={BOMstyles.headerItem}>{item.圖號}</Text>
                                <Text style={BOMstyles.headerItem}>{item.物品名}</Text>
                                <Text style={BOMstyles.headerItem}>{item.單位}</Text>
                                <Text style={BOMstyles.headerItem}>{item.數量}</Text> 
                                <Text style={BOMstyles.headerItem}>{item.單價}</Text>
                            </View>
                            <View style={BOMstyles.headerRow} key={index}>
                                <Text style={BOMstyles.headerItem}>批號:{item.批號}</Text>
                                <Text style={BOMstyles.headerItem}/>
                                <Text style={BOMstyles.headerItem}/>
                                <Text style={BOMstyles.headerItem}/>
                                <Text style={BOMstyles.headerItem}>{item.金額}</Text>
                            </View>
                        </>:null}
                    </>            
                ))}
                <View style={BOMstyles.headerRow}>
                    <Text style={BOMstyles.headerItem}>== 以下空白 ==</Text>
                    <Text style={BOMstyles.headerItem}/>
                    <Text style={BOMstyles.headerItem}/>
                    <Text style={BOMstyles.headerItem}/>
                    <Text style={BOMstyles.headerItem}/>
                </View>
                <View style={BOMstyles.headerBottomRow}>
                <Text style={BOMstyles.headerItem}>{data[0].申請人}</Text>
                <Text style={BOMstyles.headerItem}>{data[0].主管}</Text>
                <Text style={BOMstyles.headerItem}>{data[0].核准}</Text>
                <Text style={BOMstyles.headerItem}>劉芳玉</Text>
                <Text style={BOMstyles.headerItem}>黃淑媛{dateString}</Text>
                    <Text style={BOMstyles.headerItem}>金額總和:{ data.reduce((sum, item) => sum + Number(item.金額), 0)}</Text>
                </View>
                <View style={BOMstyles.headerRow}>
                    <Text style={BOMstyles.headerItem}>*產品需符合ROSH*</Text>
                    <Text style={BOMstyles.headerItem}/>
                    <Text style={BOMstyles.headerItem}/>
                    <Text style={BOMstyles.headerItem}/>
                    <Text style={BOMstyles.headerItem}/>
                </View>
                </Page>
                </>
            ))}
    </Document>
  );

