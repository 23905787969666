import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import '../../../css/matset.css'
import { apiUrl } from '../../apiUrl/ApiUrl';

export default function Buyer({data}) {
    const [newData,setNewData] = useState(null);
    const [selected, setSelected] = useState('');
    const [detailData,setDetailData] = useState(null);
    const [model,setModel] = useState('讀取模式');
    const [editData,setEditData] = useState(null);

    const [piNum,setPiNum] = useState('');
    const [needDate,setNeedDate] = useState('');
    const [buyDate,setBuyDate] = useState('');
    const [orderNum,setOrderNum] = useState('');

    console.log(data)
    const [page,setPage] = useState(1);
    // console.log(data)
    const workMargin = {
        marginTop: '3%',
        marginBottom:'5%'
    } 


    const setModelandGetDate = (newModel) =>{
        setModel(newModel);
    }

    return(
        <div className='container-fluid'>
            <div className='row'  style={workMargin}>
                <div className='col-5'/>
                <div className='col-2'>
                    <h2><span className="badge bg-success">請採購簽核</span></h2>
                </div>
                <div className='col-5'/>              
            </div>
            <div className='row' style={workMargin}>
                <div className='col-3'>
                    <ChooseModel setModel={setModel} setNewData={setNewData} setSelected={setSelected}/>
                </div>
                <div className='col-5'>
                    {model === '搜尋模式'?
                    <SelectFilter setNewData={setNewData}                    
                    setBuyDate={setBuyDate} 
                    setPiNum={setPiNum}
                    setNeedDate={setNeedDate}
                    buyDate={buyDate}
                    needDate={needDate}
                    piNum={piNum} 
                    orderNum={orderNum}
                    setOrderNum = {setOrderNum}
                    />: null }

                </div>
                <div className='col-2'/>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">生管資材卡</span></h2>
                </div>              
            </div>
            <div className='row mb-3'>
                <div className='col-12'>
                    {detailData != null ? <DetailListTable data={detailData} 
                    setDetailData={setDetailData} 
                    setModel={setModel}
                    setEditData={setEditData}/> : <div>目前沒有副表資料</div>}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">採購資料</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'/>
            </div>
            <div className='row'>
                <div className='col-12'>
                    {data != null?<ListTable setDetailData={setDetailData} 
                    setNewData={setNewData} data={newData || data} 
                    setModel={setModelandGetDate} page={page} 
                    setPage={setPage}
                    buyDate={buyDate}
                    needDate={needDate}
                    orderNum={orderNum}
                    setOrderNum = {setOrderNum}
                    piNum={piNum} /> :<div>目前還沒取得資料或取不到資料</div>}
                </div>              
            </div>
        </div>     
    )
}

function ListTable({data,setNewData,needDate,setDetailData,page,setPage,piNum,buyDate,orderNum,setOrderNum}) {
    const thStyle = {
        centered: {
            textAlign: 'center',
          }
    }
    const btnPageMargin = {
        marginLeft: '2%',
        marginRight: '2%',
    }


    const nextPage = () => {
            axios.get(`${apiUrl}/Buyer/GetMains?needDate=${needDate}&piNum=${piNum}&buyDate=${buyDate}&orderNum=${orderNum}&page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        
    }

    const prePage = () => {
            axios.get(`${apiUrl}/Buyer/GetMains?needDate=${needDate}&piNum=${piNum}&buyDate=${buyDate}&orderNum=${orderNum}&page=${page-1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        
    }


    const GetDetails = (drawNum) => {
        axios.get(`${apiUrl}/Buyer/GetCard?drawNum=${drawNum}`)
        .then(response => {
            console.log(response)
            if(response.status === 200) {
                setDetailData(response.data)
                console.log(response.data)
            }else{
                alert('找不到資料');
            }            
        })
        .catch(error =>{
            // handle error
            console.log(error);
        })
    }

    const SignOffYes = async (id) => {
        const user = sessionStorage.getItem('userName');
    
        // 構建包含查詢參數的URL
        const urlWithParams = `${apiUrl}/Buyer/SignOffYes?id=${encodeURIComponent(id)}&user=${encodeURIComponent(user)}`;
    
        try {
            // 發送一個PUT請求到你指定的URL，請注意我們不需要在此請求中提供一個請求體（body）
            const response = await axios.put(urlWithParams);  
    
            if (response.status === 200) {
                alert('簽核成功');
                axios.get(`${apiUrl}/Buyer/GetMains?needDate=${needDate}&piNum=${piNum}&buyDate=${buyDate}&orderNum=${orderNum}&page=${1}`)
                .then(response => {
                    if(response.data.statusCode === 200) {
                        setNewData(response.data)
                    }else{
                        alert('已經是最後一筆了');
                    }            
                })
                .catch(error =>{
                    // handle error
                    console.log(error);
                })
            } else {
                console.error('簽核失敗：', response);
                // 這裡處理非200狀態的回應。
            }
        } catch (error) {
            console.error('在簽核請求中發生錯誤：', error);
            // 這裡處理錯誤，例如彈出錯誤通知等。
        }
    }

    const SignOffNo = async (id) => {
        const user = sessionStorage.getItem('userName');
    
        // 构建包含查询参数的URL
        const urlWithParams = `${apiUrl}/Buyer/SignOffNo?id=${encodeURIComponent(id)}&user=${encodeURIComponent(user)}`;
    
        try {
            const response = await axios.put(urlWithParams);  // 注意，这里没有正文（body）参数
    
            if (response.status === 200) {
                alert.log('駁回成功');
                axios.get(`${apiUrl}/Buyer/GetMains?needDate=${needDate}&piNum=${piNum}&buyDate=${buyDate}&orderNum=${orderNum}&page=${1}`)
                .then(response => {
                    if(response.data.statusCode === 200) {
                        setNewData(response.data)
                    }else{
                        alert('已經是最後一筆了');
                    }            
                })
                .catch(error =>{
                    // handle error
                    console.log(error);
                })
            } else {
                alert('签核失敗');
                // 处理非200状态的响应
            }
        } catch (error) {
            alert('在签核请求中发生错误：');
            // 错误处理，例如弹出错误通知等
        }
    }

    const SignOffNull = async (id) => {
        const user = sessionStorage.getItem('userName');
    
        // 构建包含查询参数的URL
        const urlWithParams = `${apiUrl}/Buyer/SignOffNull?id=${encodeURIComponent(id)}&user=${encodeURIComponent(user)}`;
    
        try {
            const response = await axios.put(urlWithParams);  // 注意，这里没有正文（body）参数
    
            if (response.status === 200) {
                alert.log('駁回成功');
                axios.get(`${apiUrl}/Buyer/GetMains?needDate=${needDate}&piNum=${piNum}&buyDate=${buyDate}&orderNum=${orderNum}&page=${1}`)
                .then(response => {
                    if(response.data.statusCode === 200) {
                        setNewData(response.data)
                    }else{
                        alert('已經是最後一筆了');
                    }            
                })
                .catch(error =>{
                    // handle error
                    console.log(error);
                })
            } else {
                alert('签核失敗');
                // 处理非200状态的响应
            }
        } catch (error) {
            alert('在签核请求中发生错误：');
            // 错误处理，例如弹出错误通知等
        }
    }

    return(
        <>
        <div style={{overflowX: "auto", height: "80vh"}}>              
            <table className="table">
                <thead className="sticky-header">
                    <tr>
                        <th scope="col" style={thStyle.centered}>#</th>
                        <th scope="col" style={thStyle.centered}>單價</th>
                        <th scope="col" style={thStyle.centered}>金額</th>
                        <th scope="col" style={thStyle.centered}>單號</th>
                        <th scope="col" style={thStyle.centered}>批號</th>
                        <th scope="col" style={{ ...thStyle.centered, width: '200px', whiteSpace: 'nowrap' }}>圖號</th>                       
                        <th scope="col" style={thStyle.centered}>物品名</th>
                        <th scope="col" style={thStyle.centered}>操作</th>
                        <th scope="col" style={thStyle.centered}>核准</th>
                        <th scope="col" style={thStyle.centered}>廠商</th>
                        <th scope="col" style={thStyle.centered}>類別</th>
                        <th scope="col" style={thStyle.centered}>需求日期</th>
                        <th scope="col" style={thStyle.centered}>單位</th>
                        <th scope="col" style={thStyle.centered}>數量</th>                        
                        <th scope="col" style={thStyle.centered}>日期</th>
                        
                    </tr>
                </thead>
                {data.items !== null ?<tbody>
                    {data.buyerDtos.map((item, index) => (
                        <tr key={uuidv4()}>
                            <th scope="row" style={thStyle.centered}>                             
                                <Button  variant="text" size="small">{index + 1}</Button >
                            </th>
                            <td style={thStyle.centered}>{item.unitPrice}</td>
                            <td style={thStyle.centered}>{item.moneyAmount}</td>
                            <td style={thStyle.centered}>{item.orderNum}</td>
                            <td style={thStyle.centered}>{item.piNum}</td>
                            <td style={{ ...thStyle.centered, width: '200px', whiteSpace: 'nowrap' }}>{item.drawNum}</td>
                            <td style={thStyle.centered}>{item.itemName}</td>
                            <td style={thStyle.centered}>                              
                                <Button  variant="contained" size="small" onClick={() => SignOffYes(item.id)}>核准</Button >
                                <Button  variant="contained" size="small" onClick={() => SignOffNo(item.id)}>駁回</Button >
                                <Button  variant="contained" size="small" onClick={() => SignOffNull(item.id)}>取消</Button >
                                <Button  variant="contained" size="small" onClick={() => GetDetails(item.drawNum)}>資材</Button >
                            </td>
                            <td style={thStyle.centered}>{item.approve}</td>
                            <td style={thStyle.centered}>{item.manufacturer}</td>
                            <td style={thStyle.centered}>{item.kind}</td>  
                            <td style={thStyle.centered}>{item.needDate}</td>                             
                            <td style={thStyle.centered}>{item.unit}</td> 
                            <td style={thStyle.centered}>{item.amount}</td>  
                            <td style={thStyle.centered}>{item.date}</td>            
                        </tr>
                    ))}
                </tbody>:null}
                {data.buyerDto !== null ? 
                <tbody>
                    <tr key={uuidv4()}>
                            <th scope="row" style={thStyle.centered}>{1}</th>
                            <td style={thStyle.centered}>{data.storage.orderNum}</td>
                            <td style={thStyle.centered}>{data.storage.order}</td>
                            <td style={thStyle.centered}>{data.storage.manufacturers}</td>  
                            <td style={thStyle.centered}>
                                <button type="button" className="btn btn-warning" onClick={() => GetDetails(data.item.orderNum)}>詳細</button>
                            </td>             
                        </tr>
                </tbody>: null}
                
            </table>
        </div>
        <div style={{textAlign: 'center'}} className='mt-3'>
            <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => prePage()} disabled={page === 1}>上一頁</button>
                <span style={{margin: '0 10px'}}>目前在第{page}頁</span>
            <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => nextPage()}>下一頁</button>
        </div>
        </>
    )
}
function DetailListTable({ data,setDetailData,setModel,setEditData }) {
    const btnMargin = {
        marginLeft: '2%',
    };
    const thStyle = {
        centered: {
        textAlign: 'center',
        },
    };

    const handleEdit = (detail) => {
        setModel('採購入庫修改');
        setEditData(detail);
        console.log(detail)
    };

    const handleDelete = (detail) => {
        // 建立要傳送的物件
        const Data = {
        drawNum: detail.drawNum,
        company: detail.company,
        date: detail.date,
        pk: detail.pk,
        price: detail.price,
        pricePerson: detail.pricePerson,
        };

        // 發送 POST 請求到後端 API
        axios
        .post(`${apiUrl}/PurchasePrice/delete/DeleteDetail`, Data)
        .then((response) => {
            // 處理刪除成功的回應console.log(response.data)
            if (response.data === 200) {
                axios
                    .get(`${apiUrl}/PurchasePrice/DetailList?drawNum=${Data.drawNum}`)
                    .then(response => {
                        // Update the data
                        setDetailData(response.data);
                        alert('刪除成功');
                    })
                    .catch(error => {
                        // handle error
                        console.log(error);
                    });
            } else {
                alert('刪除失敗，找不到可刪除物件');
            }
        })
        .catch((error) => {
            // 處理刪除失敗的回應
            alert('刪除失敗，找不到可刪除物件')
            console.error(error);
        });
    };

    return (
        <div style={{overflowX: "auto", height: "50vh"}}>              
            <table className="table">
                <thead className="sticky-header">
                    <tr>
                    <th scope="col" style={thStyle.centered}>
                        #
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        日期
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        類別
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        單號
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        批號
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        入量
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        出量
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        庫存量
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        核准
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        領用
                    </th>
                    {/* <th scope="col" style={thStyle.centered}>
                        操作
                    </th> */}
                    </tr>
                </thead>
                <tbody>
                    {data.map((detail, index) => (
                    <tr key={uuidv4()}>
                        <th scope="row" style={thStyle.centered}>
                        {index + 1}
                        </th>
                        <td style={thStyle.centered}>{detail.日期}</td>
                        <td style={thStyle.centered}>{detail.類別}</td>
                        <td style={thStyle.centered}>{detail.圖號}</td>
                        <td style={thStyle.centered}>{detail.批號}</td>
                        <td style={thStyle.centered}>{detail.入量}</td>
                        <td style={thStyle.centered}>{detail.出量}</td>
                        <td style={thStyle.centered}>{detail.庫存量}</td>
                        <td style={thStyle.centered}>{detail.核准}</td>
                        <td style={thStyle.centered}>{detail.領用
}</td>
                        {/* <td style={thStyle.centered}>
                        <button type="button" className="btn btn-warning" onClick={() => handleEdit(detail)}
                        >修改</button>  
                        </td>  */}
                    </tr>
                    ))}
                    {/* disabled={!!detail.invoiceNum} */}
                </tbody>
            </table>
        </div>
    );
}
function ChooseModel({ setModel,selected,setSelected}) { 
    const handleChange = (event) => {
        setSelected(event.target.id);
    };
    const selectModel = (event) => {
        if (event.target.id === "btnradio2") {
            setModel('搜尋模式')
        }
        else if (event.target.id === "btnradio4") {
            setModel('讀取模式')
        }
    }
        
    return (
        <div className="btn-group-vertical">
            <label className={selected === 'btnradio2' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio2">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio2"
                    checked={selected === 'btnradio2'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>搜尋模式</strong>
            </label>
            <label className={selected === 'btnradio4' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio4">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio4"
                    checked={selected === 'btnradio4'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>讀取模式</strong>
            </label>
        </div>
    );
}

function CreateForm({editData}) {

    const [formData, setFormData] = useState({
        manufacturers: '祥達'
        // 更多的字段...
    });
    const [options, setOptions] = useState([]);
    const [selectValue, setSelectValue] = useState("");

    useEffect(() => {
        setFormData(editData || {
            manufacturers: '祥達'
            // 更多的字段...
        });
    }, [editData]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/Storage/GetOptions`);
                setOptions(response.data);
            } catch (error) {
                console.error('Failed to fetch data', error);
            }
        };

        fetchData();
    }, []);  
    console.log(editData)

    const handleInsertSubmit = async (event) => {
        event.preventDefault();
        if(formData.manufacturers === '') {
            formData.manufacturers='祥達'
        }else{
            formData.manufacturers = selectValue;
        }
        
        if(editData === null) {
            try {
                // 使用你的API URL
                const response = await axios.post(`${apiUrl}/Storage/CreateMain`, formData);
                    if (response.data.statusCode === 200) {
                        // 处理响应
                        console.log('Data successfully submitted');
                    }else{
                        alert('新增失敗 請確認欄位值')
                    }
                } 
                catch (error) {
                console.error('There was an error!', error);
                }
        }else {
            try {
                // 使用你的API URL
                const response = await axios.post(`${apiUrl}/Storage/EditMain`, formData);
                    if (response.data.statusCode === 200) {
                        // 处理响应
                        console.log('Data successfully submitted');
                    }else{
                        alert('修改失敗 請確認欄位值')
                    }
                } 
                catch (error) {
                console.error('There was an error!', error);
                }
        }

        

    };


    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));       
    };
    const handleSelectChange = (event) => {
        setSelectValue(event.target.value);
    }
    return(
        <form onSubmit={handleInsertSubmit}>
                        <div className='container-fluid'>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>入庫日期</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="date" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.date : ''}
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>採購單號</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="orderNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.orderNum : ''}
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>廠商</span>
                                </div>
                                <div className='col-2 ps-0'>
                                <select className="form-select" id="manufacturers" value={editData?editData.manufacturers:selectValue} onChange={handleSelectChange}>
                                {options.companyOptions != null?options.companyOptions.map((item, index) => (
                                    <option key={index} value={item.comSimpleName}>
                                        {`${item.comNum}  ${item.comSimpleName}  ${item.comFullName}`}
                                    </option>
                                )):null}                                
                                </select>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>對帳月份</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="reconciliationMonth" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.reconciliationMonth : ''}
                                    onChange={handleInputChange}/>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>入庫類別</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="kind" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.kind : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>發票號碼</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="invoiceNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.invoiceNum : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>序號</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="order" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.order : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>圖號</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="drawNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.drawNum : ''}
                                    onChange={handleInputChange}/>
                                </div>                             
                            </div>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>物品名</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="itemName" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.itemName : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>單位</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="unit" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.unit : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>數量</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="amount" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.amount : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>單價</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="unitPrice" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.unitPrice : ''}
                                    onChange={handleInputChange}/>
                                </div>                             
                            </div>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>金額</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="price" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.price : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>批號</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="piNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.piNum : ''}
                                    onChange={handleInputChange}/>
                                </div>                              
                            </div>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                    <button type="submit" className="btn btn-dark">提交</button>
                                </div>
                            </div>
                        </div>
                    </form>
    )
}
function SelectFilter({setNewData,piNum,setPiNum,needDate,setNeedDate,buyDate,setBuyDate,setOrderNum,orderNum}) {


    const handleSubmit = async (event) => {
        event.preventDefault();
        // Replace with your actual endpoint
        const response = await axios.get(`${apiUrl}/Buyer/GetMains?needDate=${needDate}&piNum=${piNum}&buyDate=${buyDate}&orderNum=${orderNum}&page=1`);
        if(response.data.buyerDtos != null) {
            setNewData(response.data)
        }else {
            alert('找不到資料')
        }
        
        console.log(response.data)
    };

    const clearSubmit = async (event) => {

        // Replace with your actual endpoint
        const response = await axios.get(`${apiUrl}/Buyer/GetMains?page=1`);
            setNewData(response.data)
            setPiNum('');
            setNeedDate('');
            setBuyDate('');
            setOrderNum('');
        console.log(response.data)
    };
    return(
        <form onSubmit={handleSubmit}>
        <div className='container-fluid'>
            <div className='row mb-3'>
                <div className='col-4'>
                    <label htmlFor="piNum">批號
                    <input type="text"  className="form-control" id="piNum" value={piNum} onChange={(e) => setPiNum(e.target.value)}/>
                    </label>
                </div>
                <div className='col-4'>
                    <label htmlFor="piNum">單號
                    <input type="text"  className="form-control" id="piNum" value={orderNum} onChange={(e) => setOrderNum(e.target.value)}/>
                    </label>
                </div>
                <div className='col-4'>
                    <label htmlFor="buyDate">採購日期
                    <input type="text" placeholder='yyyy-mm-dd'  className="form-control" id="buyDate" value={buyDate} onChange={(e) => setBuyDate(e.target.value)}/>
                    </label>
                </div>
                <div className='col-4'>
                    <label htmlFor="needDate">需求日期
                    <input type="text" placeholder='yyyy-mm-dd'  className="form-control" id="needDate" value={needDate} onChange={(e) => setNeedDate(e.target.value)}/>
                    </label>
                </div>
            </div>
            <div className='row' style={{ textAlign: 'center' }}>
                <div className='col-12'>
                    <button type="submit" className="btn btn-dark" style={{margin:'20px'}}>搜尋</button>
                    {/* <button type="button" className="btn btn-dark" onClick={()=>clearSubmit()}>清除搜尋條件</button> */}
                </div>
            </div>
        </div>      
  </form>
    )
}