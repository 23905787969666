import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import axios from 'axios';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { Document, Page, Text, StyleSheet, View, Image,PDFDownloadLink ,PDFViewer ,Font ,Svg, pdf } from '@react-pdf/renderer';
import { apiUrl } from '../../apiUrl/ApiUrl';



const columns2 = [
    
  {
    field: '日期',
    headerName: '日期',
    width: 100,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
    {
      field: '單號',
      headerName: '單號',
      width: 100,
      editable: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: '批號',
      headerName: '批號',
      type: 'number',
      width: 110,
      editable: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: '廠商',
      headerName: '廠商',
      type: 'number',
      width: 110,
      editable: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: '需求日',
      headerName: '需求日',
      type: 'number',
      width: 100,
      editable: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: '類別',
      headerName: '類別',
      type: 'number',
      width: 110,
      editable: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: '圖號',
      headerName: '圖號',
      type: 'number',
      width: 110,
      editable: true,
      headerAlign: 'center',
    },
    {
      field: '物品名',
      headerName: '物品名',
      type: 'number',
      width: 240,
      editable: true,
      headerAlign: 'center',
    },
    {
      field: '庫存量',
      headerName: '庫存量',
      type: 'number',
      width: 100,
      editable: true,
    },
    {
      field: '數量',
      headerName: '數量',
      type: 'number',
      width: 80,
      editable: true,
    },
    {
      field: '單價',
      headerName: '單價',
      type: 'number',
      width: 110,
      editable: true,
    },
    {
        field: '金額',
        headerName: '金額',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: '訂單號碼',
        headerName: '訂單號碼',
        width: 110,
        editable: true,
    },
    {
        field: '單位',
        headerName: '單位',
        width: 110,
        editable: true,
    },
    {
        field: '流水號',
        headerName: '流水號',
        width: 110,
        editable: true,
    },
    { field: 'id', headerName: 'ID', width: 90 },
    
  ];

const rows = [
];

export default function LaunchProduct() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const [hasMakeProcess,setHasMakeProcess] = useState(false);
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const [value, setValue] = React.useState(dayjs(formattedDate));
    const [billNums,setBillNums] = useState([]);
    const [request,setRequest] = useState({
      way:'piNum',
      num:'',
      model:'',
      qty:0,
      date:'',
      isHand:0,
      user:sessionStorage.getItem('userName')
    })
    const [row1,setRow1] = useState([])
    const [row2,setRow2] = useState([])


    const columns = [
      {
        field: '操作',
        headerName: '操作',
        width: 80,
        renderCell: (params) => (
          <IconButton
            onClick={() => handleDeleteRow(params.id)} // 設定按鈕的刪除功能
            color="primary"
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        ),
      },
      {
        field: '客戶',
        headerName: '客戶',
        width: 100,
        editable: true,
        headeralign: 'center',
      },
      {
        field: '訂單號碼',
        headerName: '訂單號碼',
        width: 110,
        editable: true,
        headeralign: 'center',
        align: 'center'
      },
      {
        field: '物品名',
        headerName: '物品名',
        type: 'number',
        width: 110,
        editable: true,
        headeralign: 'center',
        align: 'center'
      },
      {
        field: '圖號',
        headerName: '圖號',
        type: 'number',
        width: 110,
        editable: true,
        headeralign: 'center',
        align: 'center'
      },
      {
        field: '訂單數量',
        headerName: '訂單數量',
        type: 'number',
        width: 100,
        editable: true,
        headeralign: 'center',
        
      },
      {
        field: '發動數量',
        headerName: '發動數量',
        type: 'number',
        width: 100,
        editable: true,
        headeralign: 'center',
      },
      {
        field: '未發動數量',
        headerName: '未發動數量',
        type: 'number',
        width: 100,
        editable: false, // 這個字段不應該是可編輯的，因為它是計算出來的
        valueGetter: (params) => {
          const totalQty = params.row.訂單數量; // 假設您有一個名為 totalQty 的字段
          const activatedQty = params.row.發動數量 || 0; // 假設您有一個名為 activatedQty 的字段
          return totalQty - activatedQty;
        },
        
    
      },
      {
        field: '本次發動數',
        headerName: '本次發動數',
        type: 'number',
        width: 110,
        editable: true,
      },
      {
        field: '庫存量',
        headerName: '庫存量',
        type: 'number',
        width: 110,
        editable: true,
      },
      {
        field: '生產量',
        headerName: '生產量',
        type: 'number',
        width: 110,
        editable: true,
      },
      {
        field: '材料圖號',
        headerName: '材料圖號',
        type: 'number',
        width: 110,
        editable: true,
      },
      
      { field: 'id', headerName: 'ID', width: 90 },
    ];


    const handleInputChange = (value, fieldName) => {
        setRequest((prevRequest) => ({
          ...prevRequest,
          [fieldName]: value.target ? value.target.value : value, // 條件性檢查 value
        }));
      
    };
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [printItems,setPrintItems] = React.useState([]);
    const [bomOpen, setBomOpen] = React.useState(false);
    const [pdfData,setPdfData] = React.useState([]);

    const handleDeleteRow = (id) => {
      setRow1((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    
    const handleStep1Submit = async () => {
      const requestFormat = {
        way:request.way,
        num:request.num,
        model:request.model,
        qty:parseInt(request.qty, 10),
        date:value.format('YYYY-MM-DD'),
        isHand:request.isHand,
        user:sessionStorage.getItem('userName')
      }

      // const config = {
      //     headers: {
      //     'X-Ap-Token': appsetting.token,
      //     'X-Ap-CompanyId': sessionStorage.getItem('CompanyId'),
      //     'X-Ap-UserId': sessionStorage.getItem('UserId'),
      //     }
      // };     
      try {
          const response = await axios.get(`${apiUrl}/MatCard/GetStep1OrderList?way=${requestFormat.way}&num=${requestFormat.num}&isHand=${requestFormat.isHand}`);
          if (response.status === 200) {
          setRow1(response.data)
          alert('成功');
          }
      } catch (error) {
          console.error("Error logging in:", error);
          alert('找無資料 或是欄位輸入有錯');
      }                    
  }

  const handleStep2Submit = async () => {
    const requestFormat = {
      way:request.way,
      num:request.num,
      model:request.model,
      qty:parseInt(request.qty, 10),
      date:value.format('YYYY-MM-DD'),
      isHand:request.isHand,
      user:sessionStorage.getItem('userName')
    }

    // const config = {
    //     headers: {
    //     'X-Ap-Token': appsetting.token,
    //     'X-Ap-CompanyId': sessionStorage.getItem('CompanyId'),
    //     'X-Ap-UserId': sessionStorage.getItem('UserId'),
    //     }
    // };     
    try {
        const response = await axios.get(`${apiUrl}/MatCard/GetStep2UpdateOrderList?way=${requestFormat.way}&num=${requestFormat.num}&isHand=${requestFormat.isHand}&qty=${requestFormat.qty}`);
        if (response.status === 200) {
        setRow1(response.data)
        if(response.data.length > 0) {
          setRequest({
            ...request, // 複製 request 中的其他屬性
            model: response.data[0].機種 !== null? response.data[0].機種:'', // 更新 qty
          });
          alert('成功');
          }
        }
    } catch (error) {
        console.error("Error logging in:", error);
        alert('找無資料 或是欄位輸入有錯');
    }                    
}

const handleStep3Submit = async () => {
  const row1Data = row1.map(({ id, 發動數量,未發動數量,本次發動數   }) => ({
    Id: id,
    本次發動數,    
  }));
  
  const requestFormat = {
    Way:request.way,
    Num:request.num,
    Model:request.model,
    Qty:parseInt(request.qty, 10),
    Date:value.format('YYYY-MM-DD'),
    IsHand:request.isHand,
    User:sessionStorage.getItem('userName'),
    Row1Data: row1Data // 將 row1 資料加入請求格式
  }
 
  try {
    const response = await axios.post(`${apiUrl}/MatCard/GetStep3MakeData`, requestFormat);
      if (response.status === 200) {
      setRow2(response.data.datas)
      setHasMakeProcess(response.data.hasMakeProcess)
      alert('成功');
      }
  } catch (error) {
      console.error("Error logging in:", error);
      alert('找無資料 或是欄位輸入有錯');
  }                    
}

const handleStep4Submit = async () => {
  const row1Ids = row1.map(row => row.id);
  const row2Ids = row2.map(row => row.id);

  const requestFormat = {
    way:request.way,
    num:request.num,
    model:request.model,
    qty:parseInt(request.qty, 10),
    date:value.format('YYYY-MM-DD'),
    isHand:request.isHand,
    user:sessionStorage.getItem('userName')
  }
  setOpen(false);
  setLoading(true); 
  try {
    const response = await axios.post(`${apiUrl}/MatCard/SaveDataAlertStep4`, {
      user: sessionStorage.getItem('userName'),
      row1Ids: row1.map(row => row.id),
      row2Ids: row2.map(row => row.id),
    });
    
      if (response.status === 200) {
        const step2Response = await axios.get(`${apiUrl}/MatCard/SaveDataAfterAlertStep5?user=${requestFormat.user}&model=${requestFormat.model}&qty=${requestFormat.qty}&date=${requestFormat.date}&isHand=${requestFormat.isHand}&way=${requestFormat.way}&num=${requestFormat.num}`);
        if(step2Response.status === 200)  {
          setRow2(step2Response.data.list)
          setBillNums(step2Response.data.billNums)
          alert('存入資料成功');
        }

      }
      setLoading(false);
  } catch (error) {
      setLoading(false); 
      console.error("Error logging in:", error);
      alert('找無資料 或是欄位輸入有錯');
  }                    
}

const downloadExcel = async () => {

  try {
      const response = await axios.get(`${apiUrl}/MatCard/SaveDataAfterAlertStep6`, {
          params: {
              user: sessionStorage.getItem('userName'),
              model: request.model,
              qty: parseInt(request.qty, 10),
              date: value.format('YYYY-MM-DD'),
              isHand: request.isHand,
              way: request.way,
              num: request.num
          },
          
      });
      if(response.status === 200) {
        setPdfData(response.data)
        console.log(response.data)
        setBomOpen(true)
    }
      
  } catch (error) {
      console.error("There was an error downloading the file!", error);
  }
};

const downloadMakeCmdExcel = async () => {
  try {
    const response = await axios.get(`${apiUrl}/MatCard/SaveDataAfterAlertStep7`, {
      params: {
          user: sessionStorage.getItem('userName'),
          model: request.model,
          qty: parseInt(request.qty, 10),
          date: value.format('YYYY-MM-DD'),
          isHand: request.isHand,
          way: request.way,
          num: request.num
      },
      responseType: 'blob'
    });
    if (response.status === 200) {
        // 接收數據並生成 PDF
        const data = response.data;
        
        // 使用 MyDocument 組件生成 PDF
        const pdfBlob = await pdf(<MyDocument data={data} day={value.format('YYYY-MM-DD')} />).toBlob();

        // 創建 URL 並觸發下載
        const url = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '製令單.pdf'); // PDF 文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setPrintItems(data);
        setOpen(true);
    } else {
        alert('發生錯誤');
    }
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};


    if (loading) {
      return (
          <Box 
              sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  height: '85vh', 
                  backgroundColor: 'transparent' 
              }}>
              <CircularProgress color="success" />
          </Box>
      );
    }
    
  return (
    <>

         <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
        <Box sx={{ width: 300 }}>           
            <FormControl fullWidth>
            <FormLabel id="demo-row-radio-buttons-group-label">條件</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={request.isHand}
                    onChange={(e) => handleInputChange(e, 'isHand')}
                >
                    <FormControlLabel value={1} control={<Radio />} label="手動" />
                    <FormControlLabel value={0} control={<Radio />} label="台日HTC" />
                </RadioGroup>
            </FormControl>
        </Box>
        <Box sx={{ width: 300 }}>         
            <FormControl style={{width:'100px'}}>
                <InputLabel id="demo-simple-select-label">批號</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={request.way}
                label="Age"
                onChange={(e) => handleInputChange(e, 'way')}
                >
                <MenuItem value='piNum'>批號</MenuItem>
                <MenuItem value='orderNum'>訂單號碼</MenuItem>
                </Select>
            </FormControl>       
            <TextField id="outlined-basic" label="號碼" variant="outlined" value={request.num} onChange={(e) => handleInputChange(e, 'num')}/>
        </Box>
        <Box sx={{ width: 300 }}>         
            <TextField id="outlined-basic" label="機種" variant="outlined" value={request.model} onChange={(e) => handleInputChange(e, 'model')}/>          
            <TextField id="outlined-basic" label="台數QTY" variant="outlined" value={request.qty} onChange={(e) => handleInputChange(e, 'qty')}/>
        </Box>
        <Box sx={{ width: 300 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                    label="需求日"
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </Box>
    </Box>
    <Box sx={{ height: 400, width: '100%' }}>
        <Button variant="text" onClick={handleStep1Submit}>搜尋</Button>
        <Button variant="text" onClick={handleStep2Submit}>開始發動</Button>
        <Button variant="text">清除Clear</Button>
      <DataGrid
        rows={row1}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20]}
        disableRowSelectionOnClick
      />
    </Box>
    
    <Box sx={{ height: 400, width: '100%' }}>
        <Typography variant="h6" gutterBottom>
        產生採購單
        </Typography>
        <Button variant="text" onClick={handleStep3Submit}>1.產生資料</Button>
        <Button variant="text" onClick={handleClickOpen}>2.存入資料</Button>
        <Button variant="text" onClick={downloadExcel}>3.BOM用料表</Button>
        <Button variant="text" onClick={downloadMakeCmdExcel}>4.印製令Print</Button>
        <Typography variant="h6" gutterBottom>
        生產製令
        </Typography>
        <Typography variant="h6" gutterBottom>
            製令NO:
            {billNums.map((billnum, index) => (
                <span key={index}>
                    {billnum}
                    {index !== billNums.length - 1 ? ',' : ''}
                </span>
            ))}
        </Typography>
        <Typography variant="h6" gutterBottom>
        有無製程{hasMakeProcess?':有':':無'}
        </Typography>
      <DataGrid
        rows={row2}
        columns={columns2}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
    <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>確定要存入資料?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            確認好再操作
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleStep4Submit}>OK</Button>
        </DialogActions>
      </Dialog>
    </>

  );
}

Font.register({
  family: 'TaipeiSansTCBeta-Bold',
  src: 'path/to/TaipeiSansTCBeta-Bold.ttf', // 替换为您的实际字体文件路径
});
Font.register({
  family: 'TaipeiSansTCBeta-Light',
  src: 'path/to/TaipeiSansTCBeta-Light.ttf', // 替换为您的实际字体文件路径
});
// 页面宽高转换为点（1cm = 28.28点）
const pageWidth = 24.1 * 72 / 2.54;
const pageHeight = 14 * 72 / 2.54;

// 表格宽度比例，根据您的表格设计调整
const cellWidth = pageWidth / 6; // 假设有8列

// 创建样式
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 24,
    width: pageWidth,
    height: pageHeight,
  },
  onePage: {
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: 24,
    width: pageWidth,
    height: pageHeight,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'TaipeiSansTCBeta-Bold',
    marginBottom: 10,
  },
  smallTitle: {
    fontSize: 12,
    textAlign: 'flex-start',
    fontFamily: 'TaipeiSansTCBeta-Light',
    marginBottom: 20,
  },
  tableContainer: {
    flexDirection: 'row',
  },
  tableHeader: {
    backgroundColor: '#eee',
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
  },
  tableHeaderCell: {
    fontSize: 12,
    width: cellWidth,
    fontFamily: 'TaipeiSansTCBeta-Light',
    textAlign: 'center',
  },
  tableCell: {
    fontSize: 10,
    width: cellWidth,
    fontFamily: 'TaipeiSansTCBeta-Light',
    textAlign: 'center',
  },
  dayCell: {
    fontSize: 16,
    textAlign: 'flex-start',
    fontFamily: 'TaipeiSansTCBeta-Light',
    marginRight: 100,
  },
});

const BOMstyles = StyleSheet.create({
  page: {
      flexDirection: 'column',
      backgroundColor: 'white',
      padding: 24,
      width: pageWidth,
      height: pageHeight,
  },
  onePage: {
      flexDirection: 'row',
      backgroundColor: 'white',
      padding: 24,
      width: pageWidth,
      height: pageHeight,
  },
  title: {
      fontSize: 20,
      textAlign: 'center',
      fontFamily: 'TaipeiSansTCBeta-Bold',
      marginBottom: 10,
  },
  tableContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      fontFamily: 'TaipeiSansTCBeta-Bold', // 新增此行以确保容器内的内容（标题）居中
      width: '100%', // 确保容器宽度充满整个页面或其父容器
  },  
  headerRow: {
    flexDirection: 'row',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 5, // 根据需要调整，为标题和标头之间提供一些空间
  },
  headerRowDash: {
    flexDirection: 'row',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 10, // 标题和标头之间的空间
    borderTop: 1, // 添加上边框
    borderBottom: 1, // 添加下边框
    borderColor: 'black', // 边框颜色
    paddingTop: 5, // 标头内容与上边框之间的空间
    paddingBottom: 5, // 标头内容与下边框之间的空间
  },
  headerItem: {
      flex: 1, // 平分容器宽度
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
  },
  header子件料號: {
    width: '15%',
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
  },
  header品名: {
    width: '5%',
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
  },
  header規格: {
    width: '25%',
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
  },
  header配賦量: {
    width: '5%',
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
  },
  header單位: {
    width: '10%',
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
  },
  header需求量: {
    width: '10%',
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
  },
  header實領: {
    width: '10%',
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
  },
  header備註: {
    width: '20%',
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
  },
});
const currentDate = new Date();
const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份需要加1，因为它从0开始
const currentDay = String(currentDate.getDate()).padStart(2, '0');
const dateString = `Admin ${currentMonth}/${currentDay}`;
// 创建文档组件
const MyBomDocument = ({ data,day }) => ( 
  <Document>
    {data.map((item, index) => (
      <>
      <Page size={{ width: pageWidth, height: pageHeight }} style={BOMstyles.page}>
        <View style={BOMstyles.tableContainer}>
          <Text style={BOMstyles.title}>福蒙BOM用料表</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.headerItem}>主件料號:{item.主件料號}</Text>
            <Text style={BOMstyles.headerItem}>規格:{item.規格}</Text>
            <Text style={BOMstyles.headerItem}>機種:{item.機種}</Text>
            <Text style={BOMstyles.headerItem}>批量:{item.批量}</Text>
            <Text style={BOMstyles.headerItem}>日期:{day}</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.headerItem}>品名:{item.主件料號}</Text>
            <Text style={BOMstyles.headerItem}>單位:{item.單位}</Text>
            <Text style={BOMstyles.headerItem}>批號:{item.批號}</Text>
            <Text style={BOMstyles.headerItem}>Page:{index+1}                                                      /</Text>
            <Text style={BOMstyles.headerItem}>{data.length}</Text>
        </View>
        <View style={BOMstyles.headerRowDash}>
            <Text style={BOMstyles.header子件料號}>子件料號</Text>
            <Text style={BOMstyles.header品名}>品名</Text>
            <Text style={BOMstyles.header規格}>規格</Text>
            <Text style={BOMstyles.header配賦量}>配賦量</Text>
            <Text style={BOMstyles.header單位}>單位</Text>
            <Text style={BOMstyles.header需求量}>需求量</Text>
            <Text style={BOMstyles.header實領}>實領</Text>
            <Text style={BOMstyles.header備註}>備註</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號1}</Text>
            <Text style={BOMstyles.header品名}>{item.品名1}</Text>
            <Text style={BOMstyles.header規格}>{item.規格1}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量1}</Text>
            <Text style={BOMstyles.header單位}>{item.單位1}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量1}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號2}</Text>
            <Text style={BOMstyles.header品名}>{item.品名2}</Text>
            <Text style={BOMstyles.header規格}>{item.規格2}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量2}</Text>
            <Text style={BOMstyles.header單位}>{item.單位2}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量2}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號3}</Text>
            <Text style={BOMstyles.header品名}>{item.品名3}</Text>
            <Text style={BOMstyles.header規格}>{item.規格3}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量3}</Text>
            <Text style={BOMstyles.header單位}>{item.單位3}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量3}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號4}</Text>
            <Text style={BOMstyles.header品名}>{item.品名4}</Text>
            <Text style={BOMstyles.header規格}>{item.規格4}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量4}</Text>
            <Text style={BOMstyles.header單位}>{item.單位4}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量4}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號5}</Text>
            <Text style={BOMstyles.header品名}>{item.品名5}</Text>
            <Text style={BOMstyles.header規格}>{item.規格5}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量5}</Text>
            <Text style={BOMstyles.header單位}>{item.單位5}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量5}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號6}</Text>
            <Text style={BOMstyles.header品名}>{item.品名6}</Text>
            <Text style={BOMstyles.header規格}>{item.規格6}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量6}</Text>
            <Text style={BOMstyles.header單位}>{item.單位6}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量6}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號7}</Text>
            <Text style={BOMstyles.header品名}>{item.品名7}</Text>
            <Text style={BOMstyles.header規格}>{item.規格7}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量7}</Text>
            <Text style={BOMstyles.header單位}>{item.單位7}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量7}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號8}</Text>
            <Text style={BOMstyles.header品名}>{item.品名8}</Text>
            <Text style={BOMstyles.header規格}>{item.規格8}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量8}</Text>
            <Text style={BOMstyles.header單位}>{item.單位8}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量8}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號9}</Text>
            <Text style={BOMstyles.header品名}>{item.品名9}</Text>
            <Text style={BOMstyles.header規格}>{item.規格9}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量9}</Text>
            <Text style={BOMstyles.header單位}>{item.單位9}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量9}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號10}</Text>
            <Text style={BOMstyles.header品名}>{item.品名10}</Text>
            <Text style={BOMstyles.header規格}>{item.規格10}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量10}</Text>
            <Text style={BOMstyles.header單位}>{item.單位10}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量10}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號11}</Text>
            <Text style={BOMstyles.header品名}>{item.品名11}</Text>
            <Text style={BOMstyles.header規格}>{item.規格11}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量11}</Text>
            <Text style={BOMstyles.header單位}>{item.單位11}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量11}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號12}</Text>
            <Text style={BOMstyles.header品名}>{item.品名12}</Text>
            <Text style={BOMstyles.header規格}>{item.規格12}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量12}</Text>
            <Text style={BOMstyles.header單位}>{item.單位12}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量12}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        {/* <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號13}</Text>
            <Text style={BOMstyles.header品名}>{item.品名13}</Text>
            <Text style={BOMstyles.header規格}>{item.規格13}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量13}</Text>
            <Text style={BOMstyles.header單位}>{item.單位13}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量13}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View>
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.header子件料號}>{item.子件料號14}</Text>
            <Text style={BOMstyles.header品名}>{item.品名14}</Text>
            <Text style={BOMstyles.header規格}>{item.規格14}</Text>
            <Text style={BOMstyles.header配賦量}>{item.配賦量14}</Text>
            <Text style={BOMstyles.header單位}>{item.單位14}</Text>
            <Text style={BOMstyles.header需求量}>{item.需求量14}</Text>
            <Text style={BOMstyles.header實領}>_____</Text>
            <Text style={BOMstyles.header備註}>________________</Text>
        </View> */}
        <View style={BOMstyles.headerRow}>
            <Text style={BOMstyles.headerItem}>製表人:{sessionStorage.getItem('userName')+day}</Text>
            <Text style={BOMstyles.headerItem}>發料人:                                              </Text>
            <Text style={BOMstyles.headerItem}>領料人:                                              </Text>
        </View>
      </Page>
      </>             
      ))}
  </Document>
);


const MyDocument = ({ data, day }) => (
  <Document>
      {data.map((bigitem, index) => (
          <Page key={index} size={{ width: pageWidth, height: pageHeight }} style={styles.page}>
              <View style={{ height: 32 }} /> {/* 添加额外的空白区域 */}
             
              <View style={styles.tableContainer}>
                  <Text style={styles.tableHeaderCell}>製令No#</Text>
                  <Text style={styles.tableHeaderCell}>{bigitem.單號}</Text>
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell}>{day}</Text>
                  <Text style={styles.tableHeaderCell} />
              </View>
              <View style={{ height: 12 }} />
              <View style={styles.tableContainer}>
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell}>{bigitem.批號}</Text>
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell}>{bigitem.日期}</Text>
                  <Text style={styles.tableHeaderCell} />
              </View>
              <View style={styles.tableContainer}>
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell}>{bigitem.圖號}</Text>
                  <Text style={styles.tableHeaderCell} />
                  <Text
                      style={[styles.tableHeaderCell, { fontSize: 8, flexShrink: 1 }]}
                      numberOfLines={1}
                      ellipsizeMode="clip"
                  >
                      {bigitem.規格}
                  </Text>
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell}>{bigitem.生產量}</Text>
                  <Text style={styles.tableHeaderCell} />
              </View>
              <View style={styles.tableContainer}>
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell}>{bigitem.物品名}</Text>
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell}>{bigitem.素材}</Text>
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
              </View>
              <View style={styles.tableContainer}>
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
                  <Text style={styles.tableHeaderCell} />
              </View>
                           
              {bigitem.工程內容們.map((item, index1) => (
                  <React.Fragment key={index1}>
                      <View style={{ height: 2 }} />
                     
                      <View style={styles.tableContainer}>
                          <Text style={styles.tableHeaderCell} />
                          <Text style={styles.tableHeaderCell}>{item}</Text>
                          <Text style={styles.tableHeaderCell} />
                          <Text style={styles.tableHeaderCell} />
                          <Text style={styles.tableHeaderCell} />
                          {bigitem.工作職場們.map((item, index2) => (
                              index1 === index2 ? <Text key={index2} style={styles.tableHeaderCell}>{item}</Text> : null
                          ))}

                      </View>
                  </React.Fragment>
              ))}
              <View style={{ position: 'absolute', right: -32, bottom: 24 }}>
                  <Text style={[styles.dayCell, { fontSize: 8 }]}>{`Admin ${day}`}</Text>
              </View>
          </Page>
      ))}
  </Document>
);
