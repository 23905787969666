import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Document, Page, Text, StyleSheet, View, Image,PDFDownloadLink ,PDFViewer ,Font ,Svg } from '@react-pdf/renderer';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import chFont from '../../../css/font/TaipeiSansTCBeta-Bold.ttf'
import chLight from '../../../css/font/TaipeiSansTCBeta-Light.ttf'
import { apiUrl } from '../../apiUrl/ApiUrl';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function MatItemCard({data}) {
    const [newData,setNewData] = useState(null);
    const [drawNum, setDrawNum] = useState('');
    const [name, setName] = useState('');
    const [detailData,setDetailData] = useState(null);
    const [model,setModel] = useState('資材卡');


    const [page,setPage] = useState(1);
    // console.log(data)
    const workMargin = {
        marginTop: '3%',
        marginBottom:'5%'
    } 


    const setModelandGetDate = (newModel) =>{
        setModel(newModel);
    }

    return(
        <div className='container-fluid'>
            <div className='row'  style={workMargin}>
                <div className='col-5'/>
                <div className='col-2'>
                    <h2><span className="badge bg-success">{model}</span></h2>
                </div>
                <div className='col-5'/>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">資材卡</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    {detailData != null ? <DetailListTable data={detailData} 
                    setDetailData={setDetailData} 
                    drawNum={drawNum}
                    setModel={setModel}/> : <div>目前沒有副表資料</div>}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">料品表</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <SelectFilter setNewData={setNewData} setDrawNum={setDrawNum} drawNum={drawNum} setName={setName} name={name}/>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    {data != null && model === '資材卡'?<ListTable setDetailData={setDetailData} 
                    setNewData={setNewData} data={newData || data} 
                    setModel={setModelandGetDate} page={page} 
                    setPage={setPage}/> :<div>目前還沒取得資料或取不到資料</div>}
                </div>              
            </div>
        </div>     
    )
}

function ListTable({data,setNewData,setModel,setDetailData,page,setPage}) {
    const thStyle = {
        centered: {
            textAlign: 'center',
          }
    }
    const btnPageMargin = {
        marginLeft: '2%',
        marginRight: '2%',
    }


    const nextPage = () => {
            axios.get(`${apiUrl}/MatCard/GetItems?page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        
    }

    const prePage = () => {
            axios.get(`${apiUrl}/MatCard/GetItems?page=${page-1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        
    }


    const GetMats = (drawNum) => {
        axios.get(`${apiUrl}/MatCard/GetMats?drawNum=${drawNum}`)
        .then(response => {
            if(response.data.statusCode === 200) {
                setDetailData(response.data)

            }else{
                alert('找不到資料');
            }            
        })
        .catch(error =>{
            // handle error
            console.log(error);
        })
    }

    return(
        <>
            <div style={{overflowX: "auto", height: "70vh",margin:'20px'}}>
                <table className="table custom-table table-striped" style={{width: "2500px"}}>
                    <thead className="sticky-header">
                    <tr>
                        <th scope="col" style={{ textAlign: 'center', width: '30px',fontSize: '12px' }}>操作</th>
                        <th scope="col" style={{ textAlign: 'center', width: '30px',fontSize: '12px' }}>#</th>
                        <th scope="col" style={{ textAlign: 'center', width: '60px',fontSize: '12px' }}>圖號</th>
                        <th scope="col" style={{ textAlign: 'center', width: '100px',fontSize: '12px' }}>物品名</th>
                        <th scope="col" style={{ textAlign: 'center', width: '40px',fontSize: '12px' }}>單位</th>
                        <th scope="col" style={{ textAlign: 'center', width: '20px',fontSize: '12px' }}>規格</th>
                        <th scope="col" style={{ textAlign: 'center', width: '60px',fontSize: '12px' }}>安全量</th>
                        <th scope="col" style={{ textAlign: 'center', width: '60px',fontSize: '12px' }}>庫存量</th>
                        <th scope="col" style={{ textAlign: 'center', width: '60px',fontSize: '12px' }}>現有成本</th>

                    </tr>
                </thead>
                {data.items !== null ?<tbody>
                    {data.items.map((item, index) => (
                        <tr key={uuidv4()}>
                            <td style={{ textAlign: 'center', width: '30px',fontSize: '10px' }}>
                                <button type="button" className="btn btn-warning" onClick={() => GetMats(item.drawNum)}>資材卡</button>
                            </td>   
                            <th scope="row" style={{ textAlign: 'center', width: '30px',fontSize: '10px' }}>{index + 1}</th>
                            <td style={{ textAlign: 'center', width: '60px',fontSize: '12px' }}>{item.drawNum}</td>
                            <td style={{ textAlign: 'center', width: '100px',fontSize: '12px' }}>{item.itemName}</td>
                            <td style={{ textAlign: 'center', width: '40px',fontSize: '12px' }}>{item.unit}</td>  
                            <td style={{ textAlign: 'center', width: '20px',fontSize: '12px' }}>{item.specification}</td>  
                            <td style={{ textAlign: 'center', width: '60px',fontSize: '12px' }}>{item.safeAmount}</td>  
                            <td style={{ textAlign: 'center', width: '60px',fontSize: '12px' }}>{item.inventory}</td>  
                            <td style={{ textAlign: 'center', width: '60px',fontSize: '12px' }}>{item.cost}</td>  
          
                        </tr>
                    ))}
                </tbody>:null}
                {data.item !== null ? 
                <tbody>
                    <tr key={uuidv4()}>
                            <td style={{ textAlign: 'center', width: '30px',fontSize: '10px' }}>
                                <button type="button" className="btn btn-warning" onClick={() => GetMats(data.item.drawNum)}>資材卡</button>
                            </td>    
                            <th scope="row" style={{ textAlign: 'center', width: '30px',fontSize: '12px' }}>{1}</th>
                            <td style={{ textAlign: 'center', width: '60px',fontSize: '12px' }}>{data.item.drawNum}</td>
                            <td style={{ textAlign: 'center', width: '100px',fontSize: '12px' }}>{data.item.itemName}</td>
                            <td style={{ textAlign: 'center', width: '40px',fontSize: '12px' }}>{data.item.unit}</td>  
                            <td style={{ textAlign: 'center', width: '20px',fontSize: '12px' }}>{data.item.specification}</td>  
                            <td style={{ textAlign: 'center', width: '60px',fontSize: '12px' }}>{data.item.safeAmount}</td>  
                            <td style={{ textAlign: 'center', width: '60px',fontSize: '12px' }}>{data.item.inventory}</td>  
                            <td style={{ textAlign: 'center', width: '60px',fontSize: '12px' }}>{data.item.cost}</td>  
         
                        </tr>
                </tbody>: null}
                
            </table>
            </div>
            <div style={{textAlign: 'center'}} className='mt-3'>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => prePage()} disabled={page === 1}>上一頁</button>
                    <span style={{margin: '0 10px'}}>目前在第{page}頁</span>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => nextPage()}>下一頁</button>
            </div>
        </>
    )
}
function DetailListTable({ data,setDetailData,setModel, drawNum }) {

    const [start,setStart] = useState('');
    const [end,setEnd] = useState('');
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
    setOpen(false);
    };
    const btnMargin = {
      marginLeft: '2%',
    };
    const thStyle = {
      centered: {
        textAlign: 'center',
      },
    };
  
    const GetMats = (drawNum) => {
      axios.get(`${apiUrl}/MatCard/GetMats?drawNum=${drawNum}`)
      .then(response => {
          if(response.data.statusCode === 200) {
              setDetailData(response.data)

          }else{
              alert('找不到資料');
          }            
      })
      .catch(error =>{
          // handle error
          console.log(error);
      })
  }

    const handleDelete = (detail) => {
      // 建立要傳送的物件
      const Data = {
        drawNum: detail.drawNum,
        company: detail.company,
        date: detail.date,
        pk: detail.pk,
        price: detail.price,
        pricePerson: detail.pricePerson,
      };
  
      // 發送 POST 請求到後端 API
      axios
        .post(`${apiUrl}/PurchasePrice/delete/DeleteDetail`, Data)
        .then((response) => {
          // 處理刪除成功的回應console.log(response.data)
            if (response.data === 200) {
                axios
                    .get(`${apiUrl}/PurchasePrice/DetailList?drawNum=${Data.drawNum}`)
                    .then(response => {
                        // Update the data
                        setDetailData(response.data);
                        alert('刪除成功');
                    })
                    .catch(error => {
                        // handle error
                        console.log(error);
                    });
            } else {
                alert('刪除失敗，找不到可刪除物件');
            }
        })
        .catch((error) => {
          // 處理刪除失敗的回應
          alert('刪除失敗，找不到可刪除物件')
          console.error(error);
        });
    };

    const handleTimeDetail = () => {
        axios
            .get(`${apiUrl}/MatCard/TimeGetMats?drawNum=${drawNum}&start=${start}&end=${end}`)
            .then(response => {
                // Update the data
                setDetailData(response.data);

            })
            .catch(error => {
                // handle error
                console.log(error);
            });
      };
      const handleDeleteCMD = (id) => {
        axios
            .get(`${apiUrl}/MatCard/DeleteMats?id=${id}`)
            .then(response => {
              GetMats(drawNum)
            })
            .catch(error => {
                // handle error
                console.log(error);
            });
      };


    return (
    <>
    <TextField id="outlined-basic" label="起始日" value={start} onChange={(e)=>setStart(e.target.value)} variant="outlined" />
    <TextField id="outlined-basic" label="終止日" value={end} onChange={(e)=>setEnd(e.target.value)}  variant="outlined" />
    <Button variant="outlined" onClick={handleTimeDetail}>搜尋</Button>
    <Button variant="outlined" onClick={handleClickOpen}>列印</Button>
                  {/* 加上滾動條的容器 */}
                  <div style={{ height: '300px', overflowY: 'auto' }}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>申請日期</th>
                            <th>日期</th>
                            <th>類別</th>
                            <th>單號</th>
                            <th>批號</th>
                            <th>入量</th>
                            <th>出量</th>
                            <th>庫存量</th>
                            <th>核准</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.matCards.map((detail, index) => (
                            <tr key={uuidv4()}>
                                <th scope="row">
                                    {index + 1}
                                    <Button autoFocus color="inherit" onClick={() => handleDeleteCMD(detail.id)}>
                                        刪除
                                    </Button>
                                </th>
                                <td>{detail.applyDate}</td>
                                <td>{detail.date}</td>
                                <td>{detail.kind}</td>
                                <td>{detail.orderNum}</td>
                                <td>{detail.piNum}</td>
                                <td>{detail.inAmount}</td>
                                <td>{detail.outAmount}</td>
                                <td>{detail.stock}</td>
                                <td>{detail.approve}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button autoFocus color="inherit" onClick={handleClose}>
              列印預覽
            </Button>
          </Toolbar>
        </AppBar>
        <PDFViewer style={{ width: '100%', height: '100%' }}>
            <MyDocument data={data.matCards}/>
        </PDFViewer> 
      </Dialog>
      </>
    );
  }

  
  Font.register({
    family: 'TaipeiSansTCBeta-Bold',
    src: 'path/to/TaipeiSansTCBeta-Bold.ttf', // 替换为您的实际字体文件路径
  });
  Font.register({
    family: 'TaipeiSansTCBeta-Light',
    src: 'path/to/TaipeiSansTCBeta-Light.ttf', // 替换为您的实际字体文件路径
  });
  const getCurrentDate = () => {
    const now = new Date();
    const year = String(now.getFullYear()).slice(-2); // 获取后两位年份
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，加1并确保两位数字
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };
  // 页面宽高转换为点（1cm = 28.28点）
  const pageWidth = 24.1 * 72 / 2.54;
  const pageHeight = 14 * 72 / 2.54;
  
  // 表格宽度比例，根据您的表格设计调整
  const cellWidth = pageWidth / 8; // 假设有8列
  
  // 创建样式
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: 'white',
      padding: 24,
      width: pageWidth,
      height: pageHeight,
    },
    title: {
      fontSize: 20,
      textAlign: 'center',
      fontFamily: 'TaipeiSansTCBeta-Bold',
      marginBottom: 10,
    },
    smallTitle: {
      fontSize: 12,
      textAlign: 'flex-start',
      fontFamily: 'TaipeiSansTCBeta-Light',
      marginBottom: 20,
    },
    tableContainer: {
      flexDirection: 'row',
      borderWidth: 1,
      borderColor: '#000',
    },
    tableHeader: {
      borderBottomWidth: 1,
      backgroundColor: '#eee',
      borderColor: '#000',
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderColor: '#000',
      alignItems: 'center',
      height: 24,
    },
    tableHeaderCell: {
      fontSize: 12,
      width: cellWidth,
      borderRightWidth: 1,
      fontFamily: 'TaipeiSansTCBeta-Bold',
      borderColor: '#000',
      textAlign: 'center',
    },
    tableCell: {
      fontSize: 10,
      width: cellWidth,
      fontFamily: 'TaipeiSansTCBeta-Light',
      borderRightWidth: 1,
      borderColor: '#000',
      textAlign: 'center',
    },
  });

  // 创建文档组件
  const MyDocument = ({ data }) => ( 
    <Document>
      <Page size={{ width: pageWidth, height: pageHeight }} style={styles.page}>
        <View style={{ position: 'absolute', top: 24, right: 24, textAlign: 'right', fontFamily: 'TaipeiSansTCBeta-Light', fontSize: 12 }}>
            <Text>{getCurrentDate()}</Text>
            <Text render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}頁次`
            )} fixed />
        </View>
        <View style={styles.title}>
          <Text>福蒙精密工業股份有限公司</Text>
        </View>
        <View style={styles.title}>
          <Text>生管資材卡</Text>
        </View>
        <View style={styles.smallTitle}>
          <Text>圖號:{data[0].drawNum}         物品名:{data[0].itemName}</Text>
        </View>
        <View style={styles.tableContainer}>
          {/* 表头 */}
          <Text style={styles.tableHeaderCell}>申請日期</Text>
          <Text style={styles.tableHeaderCell}>日期</Text>
          <Text style={styles.tableHeaderCell}>類別</Text>
          <Text style={styles.tableHeaderCell}>單號</Text>
          <Text style={styles.tableHeaderCell}>批號</Text>
          <Text style={styles.tableHeaderCell}>入量</Text>
          <Text style={styles.tableHeaderCell}>出量</Text>
          <Text style={styles.tableHeaderCell}>庫存量</Text>
        </View>
        {/* 表格行 */}
        {data.map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}/> {/* 空的申请日期 */}
            <Text style={styles.tableCell}>{item.date}</Text>
            <Text style={styles.tableCell}>{item.kind}</Text>
            <Text style={styles.tableCell}>{item.orderNum}</Text>
            <Text style={styles.tableCell}>{item.piNum}</Text>
            <Text style={styles.tableCell}>{item.inAmount}</Text>
            <Text style={styles.tableCell}>{item.outAmount}</Text>
            <Text style={styles.tableCell}>{item.stock}</Text>
          </View>
        ))}
      </Page>
    </Document>
  );

function SelectFilter({setNewData,setDrawNum,drawNum,setName,name}) {


    const handleSubmit = async (event) => {
        event.preventDefault();
        // Replace with your actual endpoint
        const response = await axios.get(`${apiUrl}/MatCard/GetItem?drawNum=${drawNum}&&name=${name}`);
        setNewData(response.data)
        console.log(response.data)
    };
    return(
        <form onSubmit={handleSubmit}>
        <div className='container-fluid'>
            <div className='row mb-3'>
                <div className='col-6'>
                    <label htmlFor="inputText1">圖號
                    <input type="text"  className="form-control" id="inputText1" value={drawNum} onChange={(e) => setDrawNum(e.target.value)}/>
                    </label>
                </div>
                <div className='col-6'>
                    <label htmlFor="inputText1">物品名
                    <input type="text"  className="form-control" id="inputText1" value={name} onChange={(e) => setName(e.target.value)}/>
                    </label>
                </div>
            </div>
            <div className='row' style={{ textAlign: 'center' }}>
                <div className='col-12'>
                    <button type="submit" className="btn btn-dark">提交</button>
                </div>
            </div>
        </div>      
  </form>
    )
}